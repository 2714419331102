import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Box, Fade, Modal, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ZoomIn, ZoomOut, Close, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import AppConfig from '../AppSettings';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: '0 auto',
        textAlign: 'center',
    },
    selectedImage: {
        marginBottom: theme.spacing(2),
        position: 'relative',
        '& img': {
            maxWidth: '90%',
            height: 'auto',
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[3],
            transition: 'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
        },
    },
    tabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '-25px',
    },
    tab: {
        minWidth: 100,
        width: 100,
        padding: theme.spacing(1),
        margin: theme.spacing(0, 1),
        transition: 'transform 0.3s ease-in-out',
    },
    tabImage: {
        width: '140%',
        height: '100px',
        objectFit: 'cover',
        borderRadius: theme.shape.borderRadius,
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    },
    selectedTab: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6],
        transform: 'scale(1.1)',
    },
    modalImageContainer: {
        position: 'relative',
        overflow: 'hidden',
        maxHeight: '600px',
        maxWidth:'600px',
        cursor: 'move',  // Ensures you can drag when zoomed
    },
    modalImage: {
        width: '100%', 
        transition: 'transform 0.3s ease-in-out',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    zoomControls: {
        position: 'absolute',
        bottom: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: theme.spacing(2),
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(1),
        borderRadius: '50px',
    },
    modalIcons: { 
        position: 'absolute',
        top: '10px',     // Adjust the distance from the top
        right: '10px',   // Adjust the distance from the right
        color: 'red !important',   // White color for the icon  
        zIndex: 10,
    },
    navigationButtons: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        display: 'flex',
        gap: theme.spacing(1),
        zIndex: 10,
    },
    navigationButton: {
        backgroundColor: '#fff',
        color: '#000',
        '&:hover': {
            backgroundColor: '#ccc',
        },
    },
    zoomIcon: {
        color: '#fff !important',  // White zoom icons
    },
}));

const Slides = ({ images }) => {
    const classes = useStyles();
    const imageRefs = useRef([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [translatePos, setTranslatePos] = useState({ x: 0, y: 0 });
    const [touchStartPos, setTouchStartPos] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (images.length > 0) {
            setSelectedImage(AppConfig.ImageUrl + '/' + images[0]?.name);
        }
    }, [images]);

    const handleImageClick = (image, index) => {
        setFade(false);
        setTimeout(() => {
            setSelectedImage(image);
            setSelectedIndex(index);
            setFade(true);

            const imageName = image.split('/').pop(); // Extract image name from URL
            if (imageRefs.current[imageName]) {
                imageRefs.current[imageName].scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    };

    const handleOpenModal = () => {
        setZoom(1);
        setTranslatePos({ x: 0, y: 0 });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleZoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom + 0.5, 3));
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 0.5, 1));
        setTranslatePos({ x: 0, y: 0 }); // Reset position when zooming out
    };

    const handlePrevImage = () => {
        const newIndex = selectedIndex > 0 ? selectedIndex - 1 : images.length - 1;
        setSelectedIndex(newIndex);
        setSelectedImage(AppConfig.ImageUrl + '/' + images[newIndex]?.name);
    };

    const handleNextImage = () => {
        const newIndex = selectedIndex < images.length - 1 ? selectedIndex + 1 : 0;
        setSelectedIndex(newIndex);
        setSelectedImage(AppConfig.ImageUrl + '/' + images[newIndex]?.name);
    };

    // Mouse drag events
    const handleMouseDown = (e) => {
        if (zoom > 1) {
            setIsDragging(true);
            setStartPos({ x: e.clientX - translatePos.x, y: e.clientY - translatePos.y });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newTranslatePos = { x: e.clientX - startPos.x, y: e.clientY - startPos.y };
            setTranslatePos(newTranslatePos);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Touch events for mobile swiping
    const handleTouchStart = (e) => {
        if (zoom > 1) {
            setTouchStartPos({ x: e.touches[0].clientX - translatePos.x, y: e.touches[0].clientY - translatePos.y });
        }
    };

    const handleTouchMove = (e) => {
        if (zoom > 1) {
            const newTranslatePos = { x: e.touches[0].clientX - touchStartPos.x, y: e.touches[0].clientY - touchStartPos.y };
            setTranslatePos(newTranslatePos);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const handleWheel = (e) => {
        if (zoom > 1) {
            setTranslatePos((prevPos) => ({
                x: prevPos.x - e.deltaX,
                y: prevPos.y - e.deltaY,
            }));
        }
    };

    return (
        <div className={classes.root}>
            <Fade in={fade} timeout={500}>
                <div className={classes.selectedImage}>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Selected"
                            onClick={handleOpenModal}
                            style={{ cursor: 'zoom-in' }}
                            ref={(el) => {
                                if (el) {
                                    const imageName = selectedImage.split('/').pop();
                                    imageRefs.current[imageName] = el;
                                }
                            }}
                        />
                    )}
                </div>
            </Fade>
            <Tabs
                value={selectedIndex}
                className={classes.tabs}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                aria-label="image tabs"
                allowScrollButtonsMobile
            >
                {images.map((image, index) => (
                    <Tab
                        key={index}
                        icon={
                            <img
                                id={`image-${image.PackageDetailID}`}
                                src={AppConfig.ImageUrl + '/' + image.name}
                                alt={`Tab ${index}`}
                                className={`${classes.tabImage} ${index === selectedIndex ? classes.selectedTab : ''}`}
                                onClick={() => handleImageClick(AppConfig.ImageUrl + '/' + image.name, index)}
                            />
                        }
                        className={classes.tab}
                    />
                ))}
            </Tabs>

            {/* Modal for zoomed image */}
            <Modal open={modalOpen} onClose={handleCloseModal} className={classes.modal}>
                <div
                    className={classes.modalImageContainer}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onWheel={handleWheel}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <img
                        src={selectedImage}
                        alt="Zoomed"
                        className={classes.modalImage}
                        style={{
                            transform: `scale(${zoom}) translate(${translatePos.x}px, ${translatePos.y}px)`,
                            cursor: zoom > 1 ? 'move' : 'default',
                        }}
                    />
                    {/* Zoom controls */}
                    <div className={classes.zoomControls}>
                        <IconButton onClick={handleZoomIn} className={classes.zoomIcon}>
                            <ZoomIn />
                        </IconButton>
                        <IconButton onClick={handleZoomOut} className={classes.zoomIcon}>
                            <ZoomOut />
                        </IconButton>
                    </div>

                    {/* Navigation buttons */}
                    <div className={classes.navigationButtons}>

                        {/* Close button */}
                        <IconButton onClick={handleCloseModal} className={classes.modalIcons}>
                            <Close />
                        </IconButton>
                        <IconButton onClick={handlePrevImage} className={classes.navigationButton}>
                            <ArrowBackIos />
                        </IconButton>
                        <IconButton onClick={handleNextImage} className={classes.navigationButton}>
                            <ArrowForwardIos />
                        </IconButton> 

                    </div>

                </div>
            </Modal>
        </div>
    );
};

export default Slides;
