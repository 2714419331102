import axios from 'axios'
import AppConfig from '../AppSettings';



export const uploadXSDFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem("jwt");
    const headers = { 
    };

    try {
        const response = await axios.post(`${AppConfig.apiUrl}/Help/UploadXSD`, formData, { headers });
        return response.data; // Assuming the backend returns the JSON schema
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const uploadImage = async (image, relatedType, relatedid, ObjectString) => {
    const formData = new FormData();
    formData.append('ImageFile', image)
    formData.append('StrRelatedType', relatedType)
    formData.append('StrRelatedId', relatedid)
    formData.append('StrObjectString', ObjectString)
    if (image) {

        const token = localStorage.getItem("jwt");
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        return await axios
            .post(AppConfig.apiUrl + `/UploadFile/UploadImage`, formData, { headers })
            // .then(res => {
            //   data.Image = res.data[0].id;
            //   callback(data);
            // })
            .catch(err => {
                console.log(err);
            });
    }
    // else{
    //     callback(data);
    // }
}

export const DeleteFileUpload = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/UploadFile/DeleteFileUpload`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
} 


export const GetJSONXSD = async (data) => {
    try {
        const response = await fetch(AppConfig.apiUrl + `/Help/TestSerXSD`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
         

        const result = await response.json();
        return result; 
    } catch (error) {
        console.error("Fetch error:", error);
    }
}
