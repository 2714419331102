import React from 'react'; 
import AppBarCMS from './AppBarCMS';
import CategoriesPage from './CategoriesPage';
import SubCategoriesPage from './SubCategoriesPage';
import ItemPage from './ItemPage';
import FlavorPage from './FlavorPage';
import SizePage from './Sizepage';
import Brandpage from './Brandpage';
import Dashboard from './Dashboard';
import ChangePassword from './ChangePassword';  
import DisplayGetInTouch from './DisplayGetInTouch';  
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation,
    Routes
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
function HomeCMS({ isAuthorized }) {

    const navigate = useNavigate(); // Use useNavigate hook to handle navigation

    if (!isAuthorized) {
        navigate('/login')
        return;
    } 
    return (
        <div> 
            <AppBarCMS />
            <Routes>
                <Route path="/categories" element={<CategoriesPage />} exact  />
                <Route path="/Sub-Categories" element={<SubCategoriesPage />} />
                <Route path="/Flavors" element={<FlavorPage />} />
                <Route path="/Sizes" element={<SizePage />} />
                <Route path="/Brands" element={<Brandpage />} />
                <Route path="/Items" element={<ItemPage />} />
                <Route path="/DisplayGetInTouch" element={<DisplayGetInTouch />} />
                <Route path="/Dashboard" element={<Dashboard />}  />
                <Route path="/ChangePassword" element={<ChangePassword />} />
            </Routes>
        </div>
    );
}

export default HomeCMS;
