import AppConfig from '../AppSettings';

export const loginAPI = async (data) => {
    try {
        const response = await fetch(AppConfig.apiUrl + `/Auth/Login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result = await response.json(); 
        localStorage.setItem("jwt", result.JWT); 
        localStorage.setItem("UserId", result.UserId);
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const ChangePassword = async (data) => {
    try {

        const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl + `/Auth/ChangePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}