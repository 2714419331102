import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Badge,
    MenuItem,
    Menu,
    Popover,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { GetNotificationPerUser, UpdateNotification } from '../api/NotificationAPI';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import headerbgmaing2 from '../collection/headerbgmaing2.png'; // Import the image

const AppBarCSS = styled(AppBar)(({ theme }) => ({
    backgroundImage: `url(${headerbgmaing2})`, // Use the imported image
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const ToolbarComponentCMS = (props) => {
    const [notificationDBResponse, setNotificationDBResponse] = useState([]);
    const [NotificationUnreadCount, setNotificationUnreadCount] = useState(0);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

    const handleClick = (event) => {
        setNotificationUnreadCount(0);

        const notifIdsString = notificationDBResponse?.Dt?.map(notification => notification.NotifId).join(',');
        const PayloadUpdatenotif = {
            NotifIds: notifIdsString
        };
        if (notificationDBResponse?.Dt.length > 0)
            UpdateNotification(PayloadUpdatenotif);

        setNotificationAnchorEl(notificationAnchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setNotificationAnchorEl(null);
    };

    async function GetNotifications() {
        const PayloadGetnotif = {
            UserId: localStorage.getItem("UserId"),
            RestId: localStorage.getItem("RestaurantId"),
        };

        const CnstNotif = [];
        setNotificationUnreadCount(CnstNotif?.Dt?.filter(notification => notification.IsRead === false).length);
        setNotificationDBResponse(CnstNotif);
    }

    useEffect(() => {
        GetNotifications();
    }, []);

    const history = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const handleProfileMenuOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMobileMenuOpen = (e) => {
        setMobileMoreAnchorEl(e.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
    };

    const RedirectFct = (route) => {
        history(route);
    };

    const RedirectFctWithClose = (route) => {
        handleClose();
        history(route);
    };

    function logOut() {
        history("/login");
        localStorage.clear();
    }

    const RedirectURL = (route) => {
        handleMenuClose();
        history(route);
    };

    const menuId = "primary-search-account-menu";

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => RedirectURL('ChangePassword')}><LockOpenIcon />Profile</MenuItem>
            <MenuItem onClick={logOut}><ExitToAppOutlinedIcon />LogOut</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(mobileMoreAnchorEl)}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const openNotif = Boolean(notificationAnchorEl);
    const idNotif = openNotif ? 'notification-popover' : undefined;

    return (
        <div>
            <AppBarCSS position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.openDrawerHandler} >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6"  style={{ textAlign: 'Left', flex: 1 }} onClick={() => RedirectFct('Dashboard')}>
                        ENERGIZE
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <div>
                        {/*<IconButton aria-label="show new notifications" color="inherit" onClick={handleClick}>*/}
                        {/*    <Badge badgeContent={NotificationUnreadCount} color="secondary">*/}
                        {/*        <NotificationsIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        {/*<Popover*/}
                        {/*    id={idNotif}*/}
                        {/*    open={openNotif}*/}
                        {/*    anchorEl={notificationAnchorEl}*/}
                        {/*    onClose={handleClose}*/}
                        {/*    anchorOrigin={{*/}
                        {/*        vertical: 'bottom',*/}
                        {/*        horizontal: 'center',*/}
                        {/*    }}*/}
                        {/*    transformOrigin={{*/}
                        {/*        vertical: 'top',*/}
                        {/*        horizontal: 'center',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <List style={{ padding: 0 }}>*/}
                        {/*        {notificationDBResponse?.Dt?.map((notification, index) => (*/}
                        {/*            <ListItem*/}
                        {/*                key={index}*/}
                        {/*                button*/}
                        {/*                onClick={() => RedirectFctWithClose(notification?.OnClickRedirectTo)}*/}
                        {/*                style={{*/}
                        {/*                    backgroundColor: notification?.IsRead === false ? '#f9f9f9' : 'transparent',*/}
                        {/*                    borderRadius: '8px',*/}
                        {/*                    margin: '8px 0',*/}
                        {/*                    padding: '12px 16px',*/}
                        {/*                    cursor: 'pointer',*/}
                        {/*                    transition: 'background-color 0.3s',*/}
                        {/*                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',*/}
                        {/*                    borderLeft: notification?.IsRead === false ? '4px solid #ff5722' : 'none',*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <ListItemText*/}
                        {/*                    primary={notification?.MessageNotif}*/}
                        {/*                    secondary={new Date(notification?.CreationDateTime).toLocaleString()}*/}
                        {/*                    primaryTypographyProps={{*/}
                        {/*                        style: {*/}
                        {/*                            fontWeight: notification?.IsRead === false ? 'bold' : 'normal',*/}
                        {/*                            color: notification?.IsRead === false ? '#333' : '#666',*/}
                        {/*                        },*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            </ListItem>*/}
                        {/*        ))}*/}
                        {/*    </List>*/}
                        {/*</Popover>*/}

                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBarCSS>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
};

export default ToolbarComponentCMS;
