import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { GetSize } from '../api/SizesAPI';
import { GetFlavor } from '../api/FlavorsAPI';
import { GetPackageDetailsImages } from '../api/PackageDetailsImageAPI';
import { GetPackageDetails } from '../api/PackageDetailsAPI';
import AppConfig from '../AppSettings';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { blue, red, green, pink } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';

const PackageDetailDialog = ({ open, onClose, onSave, packageDetail }) => {
    const [StatepackgeDetails, setPackageDetail] = useState({
        PackageDetailID: Date.now(),
        ItemId: null,
        SizeId: '',
        FlavorId: '',
        SizeName: '',
        FlavorName: '',
        OriginalPrice: '',
        CurrentPrice: '',
        Currency: 'USD',
        IsOnSale: false,
        IsOutOfStock: false,
        IsNewArrival: false,
        Enabled:true,
        Discount: '',
        Images: [],
       
    }); 
    const [sizes, setSizes] = useState([]);
    const [flavors, setFlavors] = useState([]);
    const [removedImages, setRemovedImages] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sizesResp = await GetSize({});
                setSizes(sizesResp?.Dt);

                const flavorsResp = await GetFlavor({});
                setFlavors(flavorsResp?.Dt);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);
    const validateForm = () => {
        let valid = true;
        const errors = {};

        if (!StatepackgeDetails.SizeId) {
            errors.SizeId = 'Size is required';
            valid = false;
        }

        if (!StatepackgeDetails.FlavorId) {
            errors.FlavorId = 'Flavor is required';
            valid = false;
        }
         
        setErrors(errors);
        return valid;
    };

    useEffect(() => {
        if (open) {
            if (packageDetail) {
                const fetchData = async () => {
                    try {
                        const PackDetailId = packageDetail?.PackageDetailID
                        const CheckIfRowExistInDBToAssignImages = await GetPackageDetails({ PackageDetailID: PackDetailId });
                        if (CheckIfRowExistInDBToAssignImages?.Dt?.length > 0) {
                            //if it's in db and need update then get image and override them, if it's in the grid but not in the db so don't override it
                            const PackGetIgs = await GetPackageDetailsImages({ PackageDetailsId: PackDetailId });

                            packageDetail.Images = packageDetail.Images || [];

                            const newImages = PackGetIgs?.Dt || [];
                            const existingImageIds = new Set(packageDetail.Images.map(image => image.PackageDetailsImageId));

                            // Add new images only if they don't already exist
                            const mergedImages = [
                                ...packageDetail.Images,
                                ...newImages.filter(image => !existingImageIds.has(image.PackageDetailsImageId))
                            ];

                            packageDetail.Images = mergedImages;
                        }


                        //packageDetail.Images = [
                        //    ...packageDetail.Images,
                        //    ...packageDetail?.AllImagesContainedImagesFromDialog || []
                        //]; 

                        setPackageDetail(packageDetail);
                    } catch (error) {
                        console.error('Failed to fetch data', error);
                    }
                };
                fetchData();
            }
            else {
                setPackageDetail({
                    PackageDetailID: Date.now(),
                    ItemId: null,
                    SizeId: null,
                    FlavorId: null,
                    SizeName: null,
                    FlavorName: null,
                    OriginalPrice: null,
                    CurrentPrice: null,
                    Currency: 'USD',
                    IsOnSale: false,
                    IsOutOfStock: false,
                    IsNewArrival: false,
                    Enabled: true,
                    Discount: null,
                    Images: [],
                });
            }
        }

    }, [open, packageDetail]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedDetail = { ...StatepackgeDetails, [name]: value };

        if (name === 'SizeId') {
            const selectedSize = sizes.find(size => size.SizeId === value);
            updatedDetail = { ...updatedDetail, SizeName: selectedSize?.SizeName || null };
        }

        if (name === 'FlavorId') {
            const selectedFlavor = flavors.find(flavor => flavor.FlavorId === value);
            updatedDetail = { ...updatedDetail, FlavorName: selectedFlavor?.FlavorName || null };
        }

        setPackageDetail(updatedDetail);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setPackageDetail(prevState => ({ ...prevState, [name]: checked }));
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            setPackageDetail(prevState => ({
                ...prevState,
                Images: [...prevState.Images, ...files],
            }));
        }
    };

    const handleRemoveImage = (index) => {
        const imageToRemove = StatepackgeDetails.Images[index];
        if (imageToRemove.PackageDetailsFileName) {
            setRemovedImages(prevState => [...prevState, imageToRemove]);
        }
        setPackageDetail(prevState => ({
            ...prevState,
            Images: prevState.Images.filter((_, i) => i !== index),
        }));
    };

    const handleOpenImageInNewTab = (image) => {
        const imageUrl = image.PackageDetailsFileName
            ? `${AppConfig.ImageUrl}/${image.PackageDetailsFileName}`
            : URL.createObjectURL(image);
        window.open(imageUrl, '_blank', 'noopener,noreferrer');
    };
    const handleSubmit = () => {


        const isValid = validateForm();
        if (isValid) { 

            const finalDetails = {
                ...StatepackgeDetails,
                SizeId: StatepackgeDetails.SizeId === '' ? null : StatepackgeDetails.SizeId,
                FlavorId: StatepackgeDetails.FlavorId === '' ? null : StatepackgeDetails.FlavorId,
                OriginalPrice: StatepackgeDetails.OriginalPrice === '' ? null : StatepackgeDetails.OriginalPrice,
                CurrentPrice: StatepackgeDetails.CurrentPrice === '' ? null : StatepackgeDetails.CurrentPrice,
                Currency: StatepackgeDetails.Currency === '' ? null : StatepackgeDetails.Currency,
                Discount: StatepackgeDetails.Discount === '' ? null : StatepackgeDetails.Discount,

                RemovedImages: removedImages,
            };

            onSave(finalDetails);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle>{packageDetail ? 'Edit Package Detail' : 'Add Package Detail'}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense" required error={!!errors.SizeId}>
                    <InputLabel id="size-select-label">Size</InputLabel>
                    <Select
                        label="Size"
                        labelId="size-select-label"
                        id="Size-select"
                        name="SizeId"
                        value={StatepackgeDetails.SizeId || ''}
                        onChange={handleChange}
                    >
                        {sizes?.map((size) => (
                            <MenuItem key={size.SizeId} value={size.SizeId}>
                                {size.SizeName}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.SizeId && <div style={{ color: 'red' }}>{errors.SizeId}</div>}

                </FormControl>
                <FormControl fullWidth margin="dense" required error={!!errors.FlavorId}>
                    <InputLabel id="flavor-select-label">Flavor</InputLabel>
                    <Select
                        label="Flavor"
                        labelId="flavor-select-label"
                        id="Flavor-select"
                        name="FlavorId"
                        value={StatepackgeDetails.FlavorId || ''}
                        onChange={handleChange}
                    >
                        {flavors?.map((flavor) => (
                            <MenuItem key={flavor.FlavorId} value={flavor.FlavorId}>
                                {flavor.FlavorName}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.FlavorId && <div style={{ color: 'red' }}>{errors.FlavorId}</div>}

                </FormControl>
                <TextField
                    id="originalPrice"
                    type="number"
                    label="Original Price"
                    name="OriginalPrice"
                    value={StatepackgeDetails.OriginalPrice || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    style={{ display: 'none' }} // This will hide the component
                />
                <TextField
                    id="currentPrice"
                    type="number"
                    label="Price"
                    name="CurrentPrice"
                    value={StatepackgeDetails.CurrentPrice || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    id="discount"
                    type="number"
                    label="Discount"
                    name="Discount"
                    value={StatepackgeDetails.Discount || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    style={{ display: 'none' }} // This will hide the component
                />
                <TextField
                    id="currency"
                    label="Currency"
                    name="Currency"
                    value={StatepackgeDetails.Currency || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    style={{ display: 'none' }} // This will hide the component
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={StatepackgeDetails.IsOnSale}
                            onChange={handleCheckboxChange}
                            name="IsOnSale"
                            color="primary"
                        />
                    }
                    style={{ display: 'none' }} // This will hide the component
                    label="On Sale"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={StatepackgeDetails.Enabled}
                            onChange={handleCheckboxChange}
                            name="Enabled"
                            color="primary"
                        />
                    }
                    label="Enabled"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={StatepackgeDetails.IsOutOfStock}
                            onChange={handleCheckboxChange}
                            name="IsOutOfStock"
                            color="primary"
                        />
                    }
                    label="Out of Stock"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={StatepackgeDetails.IsNewArrival}
                            onChange={handleCheckboxChange}
                            name="IsNewArrival"
                            color="primary"
                        />
                    }
                    style={{ display: 'none' }} // This will hide the component
                    label="New Arrival"
                />

                {/* File Upload for Images */}
                <input
                    accept="image/*"
                    id="image-upload-PackageDetails"
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                />
                <label htmlFor="image-upload-PackageDetails">
                    <Button variant="outlined" component="span" fullWidth>
                        Upload Images
                    </Button>
                </label>

                {/* Display Uploaded Images */}
                {StatepackgeDetails?.Images?.length > 0 && (
                    <Grid container spacing={1} mt={2}>
                        {StatepackgeDetails?.Images?.map((packDetImage, index) => (
                            <Grid item key={index} xs={6} md={4}>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <img
                                        src={packDetImage.PackageDetailsFileName ? `${AppConfig.ImageUrl}/${packDetImage.PackageDetailsFileName}` : URL.createObjectURL(packDetImage)}
                                        alt={`Image ${index + 1}`}
                                        style={{ width: '100px', height: '100px' }}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: '85px', // Adjust as necessary to position buttons next to the image
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start', // Align buttons to start of the container
                                    }}>
                                        <IconButton
                                            style={{
                                                color: 'white',
                                                backgroundColor: '#eee',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#ddd',
                                                },
                                            }}
                                        >
                                            <DeleteIcon onClick={() => handleRemoveImage(index)} sx={{ color: pink[500] }} />
                                        </IconButton>
                                        <IconButton
                                            style={{
                                                color: 'white',
                                                backgroundColor: '#eee',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                marginTop: '5px', // Add some spacing between buttons if needed
                                                '&:hover': {
                                                    backgroundColor: '#ddd',
                                                },
                                            }}
                                        >
                                            <ZoomInOutlinedIcon onClick={() => handleOpenImageInNewTab(packDetImage)} sx={{ color: green[500] }} />
                                        </IconButton>
                                    </div>
                                </div>
                            </Grid>

                        ))}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PackageDetailDialog;
