import React, { useState } from 'react';
import { TextField, MenuItem } from '@mui/material';

const QuantityDropdownAddCart = ({ onChange }) => {
    const [quantity, setQuantity] = useState(1);

    const handleChange = (event) => {
        const value = event.target.value;
        setQuantity(value);
        onChange(value);  // Call the onChange function passed from the parent component
    };

    return (
        <TextField
            select
            label="Qty"
            value={quantity}
            onChange={handleChange}
            variant="outlined" 
        >
            {[1, 2, 3, 4, 5,6,7,8,9,10].map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default QuantityDropdownAddCart;
