import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppConfig from '../AppSettings';

const ImageSlider = ({ Products, onImageClick }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        pauseOnHover: true,
        cssEase: "ease-in-out",
        responsive: [
            {
                breakpoint: 1920,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
                breakpoint: 1024,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
                breakpoint: 600,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
        ],
    };

    return (
        <div className="image-slider-container">
            <Slider {...settings}>
                {Products.map((product, index) => (
                    <div key={index} className="slide">
                        <img
                            src={`${AppConfig.ImageUrl}/${product.ItemFileName}`}
                            alt={product.ItemName || "Product Image"}
                            className="slider-image"
                            onClick={() => onImageClick(product)}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
