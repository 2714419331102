import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import { InsertSubCategory, UpdateSubCategorys } from '../api/SubCategoriesAPI';
import { GetCategorys } from '../api/CategoriesApis';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const SubCategorieFormDialog = ({ open, item, onClose, onSubmit }) => {
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (open) {
            const fetchCategoriesData = async () => {
                try {
                    const response = await GetCategorys({});
                    setCategories(response?.Dt);
                } catch (error) {
                    console.error('Failed to fetch categories', error);
                }
            };

            fetchCategoriesData();
        } else {
            setCategories([]); // Clear categories when the dialog is closed
        }
    }, [open]);

    const validationSchema = Yup.object({
        SubCatNameFirstLang: Yup.string().required('Name is required'),
        CatId: Yup.number().required('Category is required'),
    });

    const handleClose = () => {
        setError(null); // Clear error state
        onClose();
    };

    const handleNumberChange = (handleChange) => (event) => {
        const { name, value } = event.target;
        // Ensure the value is stored as a number in the form state
        handleChange({ target: { name, value: value === '' ? null : Number(value) } });
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" sx={{
            '& .MuiPaper-root': {
                maxWidth: '400px', // Optional: Set max-width for larger screens
                /* margin: '0 auto',*/
            },
        }}>
            <DialogTitle>{item ? 'Edit SubCat' : 'Add New SubCat'}</DialogTitle>
            <Formik
                initialValues={{
                    CatId: item ? item.CatId : null,
                    SubCatId: item ? item.SubCatId : null,
                    SubCatNameFirstLang: item ? item.SubCatNameFirstLang : null,
                    SubCatOrderNbr: item ? item.SubCatOrderNbr : null,
                    IsSubCatVisible: item ? item.IsSubCatVisible : true
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        if (item) {
                            const UpdateRes = await UpdateSubCategorys(values);
                            const errorMsg = UpdateRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error updating sub-category: ' + errorMsg);
                                return;
                            }
                        } else {
                            const InsertRes = await InsertSubCategory(values);
                            const errorMsg = InsertRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error adding category: ' + errorMsg);
                                return;
                            }
                        }
                        onSubmit();
                    } catch (error) {
                        console.error('Failed to submit sub-category form', error);
                        setError('Failed to submit sub-category form. Please try again.'); // Set error message
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form>
                        <DialogContent>
                            {error && <Alert severity="error">{error}</Alert>}
 

                            <FormControl fullWidth margin="dense" error={touched.CatId && Boolean(errors.CatId)} required>
                                <InputLabel id="category-select-label">Category</InputLabel>
                                <Select
                                    label="Category"
                                    labelId="category-select-label"
                                    id="category-select"
                                    name="CatId"
                                    value={values.CatId}
                                    onChange={handleChange}
                                >
                                    {categories?.map((category) => (
                                        <MenuItem key={category.CatId} value={category.CatId}>
                                            {category.CatNameFirstLang}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.CatId && <div style={{ color: 'red' }}>{errors.CatId}</div>}
                            </FormControl>



                            <TextField
                                required
                                id="txtName"
                                label="Sub-Cat Name"
                                name="SubCatNameFirstLang"
                                value={values.SubCatNameFirstLang}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                error={touched.SubCatNameFirstLang && Boolean(errors.SubCatNameFirstLang)}
                                helperText={touched.SubCatNameFirstLang && errors.SubCatNameFirstLang}
                            />

                            <TextField
                                id="txtOrder"
                                type="number"
                                label="Order Nbr"
                                name="SubCatOrderNbr"
                                value={values.SubCatOrderNbr}
                                onChange={handleNumberChange(handleChange)}
                                fullWidth
                                margin="dense"
                            />
                            <FormControlLabel
                                sx={{ ml: '5px' }} // Adjust the value as needed
                                labelPlacement="start"
                                label="Visible"
                                control={
                                    <Checkbox
                                        checked={values.IsSubCatVisible}
                                        onChange={handleChange}
                                        name="IsSubCatVisible"
                                        color="primary"
                                    />
                                }
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                {item ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default SubCategorieFormDialog;
