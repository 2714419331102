import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@mui/x-data-grid'; 
import {
    Button,
    IconButton,
    CircularProgress,
    Snackbar,
    Container,
    TextField,
    Typography,
    Grid,
    InputAdornment
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from "react-router-dom"; 
import { useNavigate } from 'react-router-dom';  
import moment from 'moment';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import DetailsOutlinedIcon from '@mui/icons-material/DetailsOutlined';
import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Alert } from '@mui/material';
import { Select_Cat_SubCat_Item_COUNT, GetStopDate } from '../api/DashboardAPI';

export default function BasicTable() {
    const [Cat_SubCat_Item_COUNT, setCat_SubCat_Item_COUNT] = useState([])
    const [remainingDays, setRemainingDays] = useState(null);
    const [targetDate, settargetDate] = useState(null);

    const Navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const CountsReposnse = await Select_Cat_SubCat_Item_COUNT();
            const DateReposnse = await GetStopDate();
             
            setCat_SubCat_Item_COUNT(CountsReposnse);
            settargetDate(DateReposnse);
        } 
        fetchData();
    }, []);

    useEffect(() => {
        if (!targetDate) return;

        const parsedTargetDate = moment(targetDate);
        const today = moment();
        const daysDiff = parsedTargetDate.diff(today, 'days');
        setRemainingDays(daysDiff);
    }, [targetDate]);

    const getLabelClassName = () => {
        if (remainingDays !== null && remainingDays < 20) {
            return "remaining-days-label remaining-days-label-red";
        }
        return "remaining-days-label remaining-days-label-green";
    };

    const getDaysCountClassName = () => {
        if (remainingDays !== null && remainingDays < 20) {
            return "days-count days-count-red";
        }
        return "days-count days-count-green";
    };

    const Square = ({ value, label, color, onClick, icon: Icon }) => (
        <div style={{ ...styles.square, backgroundColor: color }} onClick={onClick}>
            <div style={styles.value}>
                {Icon && <Icon style={styles.icon} />}
                {value}
            </div>
            <div style={styles.label}>{label}</div>
        </div>
    );

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            marginTop: '20px',
        },
        square: {
            width: 'calc(20% - 80px)',
            minWidth: '150px',
            maxWidth: '250px',
            minHeight: '100px',
            margin: '10px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0)',
        },
        value: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        label: {
            fontSize: '16px',
        },
        icon: {
            color: '#fff',
        },
    };

    styles.square['&:hover'] = {
        backgroundColor: '#555',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
    };

    const RedirectFct = (route) => {
        Navigate(route);
    };

    return (
        <div>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <div style={styles.container}>
                {/*<Square value="Views" label={Cat_SubCat_Item_COUNT?.ViewsCount} color="#4CAF50" icon={VisibilityOutlinedIcon} onClick={() => RedirectFct('/CMS/DashboardViews')} />*/}
                <Square value="Categories" icon={CategoryOutlinedIcon} label={Cat_SubCat_Item_COUNT?.CatCount} color="#2196F3" onClick={() => RedirectFct('/CMS/Categories')} />
                <Square value="Sub-Categories" icon={ScatterPlotOutlinedIcon} label={Cat_SubCat_Item_COUNT?.SubCatCount} color="#FF9800" onClick={() => RedirectFct('/CMS/Sub-Categories')} />
                <Square value="Items" icon={DragIndicatorOutlinedIcon} label={Cat_SubCat_Item_COUNT?.ItemCount} color="#E91E63" onClick={() => RedirectFct('/CMS/Items')} />
                <Square value="Comments" icon={FeedbackOutlinedIcon} label={Cat_SubCat_Item_COUNT?.CommentsCount} color="#9C27B0" onClick={() => RedirectFct('/CMS/DisplayGetInTouch')} ></Square>

            </div>
            {/*<div className="remaining-days-container">*/}
            {/*    {remainingDays !== null && (*/}
            {/*        <div className="remaining-days">*/}
            {/*            <TimerOutlinedIcon className="duration-icon" />*/}
            {/*            <span className={getLabelClassName()}>*/}
            {/*                Days remaining until license renewal*/}
            {/*                <br />*/}
            {/*                {moment(targetDate).format('YYYY-MM-DD')}*/}
            {/*            </span>*/}
            {/*            <span className={getDaysCountClassName()}>{remainingDays} Days</span>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div> */}
        </div>
    );
}
