import React, { useState, useEffect } from 'react';
import {
    TextField,
    List,
    ListItem,
    ListItemText,
    Paper,
    CircularProgress,
    ListItemAvatar,
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Typography,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { GetItemsForSearch } from '../api/ItemsAPI';
import AppConfig from '../AppSettings';
import { useDebounce } from 'use-debounce';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: 'rgb(90, 151, 145)',
    fontSize: '1.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    },
}));

const NoResultsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const LoadingWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

const SearchPage = ({ open, onClose }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValue, 400);
    const [itemResult, setItemResult] = useState([]);
    const [catResult, setCatResult] = useState([]);
    const [subCatResult, setSubCatResult] = useState([]);
    const [brandResult, setBrandResult] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (debouncedSearchValue) {
                setLoading(true);
                try {
                    const paramsSelect = { InputSearchValue: debouncedSearchValue };
                    const dtObject = await GetItemsForSearch(paramsSelect);
                    setItemResult(dtObject?.dtItems || []);
                    setCatResult(dtObject?.dtCats || []);
                    setSubCatResult(dtObject?.dtSubCats || []);
                    setBrandResult(dtObject?.dtBrands || []);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                    setItemResult([]);
                    setCatResult([]);
                    setSubCatResult([]);
                    setBrandResult([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setItemResult([]);
                setCatResult([]);
                setSubCatResult([]);
                setBrandResult([]);
            }
        };

        fetchData();
    }, [debouncedSearchValue]);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleClose = () => {
        setSearchValue('');
        onClose();
    };

    const handleItemClick = (item) => {
        navigate(`/Product_PD/${item.ItemName.replace(/\s/g, '-')}`);
        handleClose();
    };

    const handleCategoryClick = (category) => {
        navigate(`/ProductListByCat/${category?.CatNameFirstLang.replace(/\s/g, '-')}`)
        handleClose();
    };

    const handleSubCategoryClick = (subCategory) => {
        navigate(`/ProductList/${subCategory?.SubCatNameFirstLang.replace(/\s/g, '-')}`);
        handleClose();
    };

    const handleBrandClick = (brand) => {
        navigate(`/ProductListByBrand/${brand?.BrandName.replace(/\s/g, '-')}`)
        handleClose();
    };

    const renderList = (items, onClick, idKey, nameKey, avatarKey) => {
        return items.length > 0 ? (
            <List>
                {items.map((item) => (
                    <StyledListItem button onClick={() => onClick(item)} key={item[idKey]} alignItems="center">
                        {avatarKey && (
                            <ListItemAvatar>
                                <Avatar
                                    sx={{ width: 100, height: 100 }}  // Added marginRight to push text
                                    src={item[avatarKey] ? `${AppConfig.ImageUrl}/${item[avatarKey]}` : null}
                                />
                            </ListItemAvatar>
                        )}
                        <ListItemText
                            sx={{ 
                                paddingLeft: 1,  // Added paddingLeft to push text to the right
                            }}
                        >
                            <Typography variant="body1" fontWeight="bold">
                                {item[nameKey]}
                            </Typography>
                        </ListItemText>
                    </StyledListItem>
                ))}
            </List>
        ) : null;
    };

    const renderListOtherThanItems = (items, onClick, idKey, nameKey, avatarKey) => {
        return items.length > 0 ? (
            <Box sx={{ '& > :not(style)': { m: 1 } }}>

                {items.map((item) => (
                    <Fab onClick={() => onClick(item)} key={item[idKey]} variant="extended" size="medium" color="primary">
                        {item[nameKey]}
                    </Fab>
                ))} 
            </Box>
        ) : null;
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '500px',
                    borderRadius: '15px',
                    overflow: 'hidden',
                    marginTop: '0',
                    height: '100vh',
                    width: '100vw',
                    position: 'absolute',
                    top: 0,
                },
            }}
        >
            <DialogTitle id="form-dialog-title">
                Search
                <IconButton
                    edge="end"
                    color="error"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: { xs: 2, sm: 3 } }}>
                <StyledTextField
                    autoFocus
                    index="1"
                    variant="outlined"
                    placeholder="Enter search term�"
                    value={searchValue}
                    onChange={handleSearchChange}
                    fullWidth
                    inputProps={{ autoFocus: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                {loading ? (
                    <></>
                ) : (
                    <>


                        {brandResult.length > 0 && (
                            <>
                                <SectionTitle>Brands</SectionTitle>
                                    {renderListOtherThanItems(brandResult, handleBrandClick, 'BrandId', 'BrandName')}
                                    <br />
                                    <br />
                            </>
                        )}
                        {catResult.length > 0 && (
                            <>
                                <SectionTitle>Categories</SectionTitle>
                                    {renderListOtherThanItems(catResult, handleCategoryClick, 'CatId', 'CatNameFirstLang')}
                                    <br />
                                    <br />
                            </>
                        )} 
                        {subCatResult.length > 0 && (
                            <>
                                <SectionTitle>Sub-Categories</SectionTitle>
                                    {renderListOtherThanItems(subCatResult, handleSubCategoryClick, 'SubCatId', 'SubCatNameFirstLang')}
                                    <br />
                                    <br />
                            </>
                        )}

                            {itemResult.length > 0 && (
                                <>
                                    <SectionTitle>Products</SectionTitle>
                                    {renderList(itemResult, handleItemClick, 'ItemId', 'ItemName', 'ItemFileName')}
                                    <br />
                                    <br />
                                </>
                            )}
                        {itemResult.length === 0 &&
                            catResult.length === 0 &&
                            subCatResult.length === 0 &&
                            brandResult.length === 0 && (
                                <NoResultsWrapper>
                                    <Typography variant="h6">No results found</Typography>
                                    <Typography variant="body2">Try a different search term.</Typography>
                                </NoResultsWrapper>
                            )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SearchPage;
