import React, { useState, useEffect } from "react"; 
import DrawerCMS from "./DrawerCMS";
import ToolbarComponentCMS from "./ToolBarCMS";
import { Select_Cat_SubCat_Item_COUNT } from '../api/DashboardAPI'; 
 


export function AppBarCMS() {
    const [Cat_SubCat_Item_COUNT, setCat_SubCat_Item_COUNT] = useState([]) 
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [RestnameResponse, setRestnameResponse] = useState([]);
    const toggleDrawer = () => {
        setDrawerOpen(false);
    };
    const openDrawer = () => {
        setDrawerOpen(true);
    };




    useEffect(() => {
        async function fetchData() {
            const data = await Select_Cat_SubCat_Item_COUNT();
            setCat_SubCat_Item_COUNT(data);
        }
        fetchData();
          
    }, [isDrawerOpen]); // Empty dependency array ensures this effect runs only once isDrawerOpen changed


    return (

        <div className="App">
            <ToolbarComponentCMS openDrawerHandler={openDrawer} RestName={RestnameResponse} />
            <DrawerCMS open={isDrawerOpen} toggleDrawerHandler={toggleDrawer} PassCounts={Cat_SubCat_Item_COUNT} RestName='ENERGIZE' />
        </div>
         
    );
}

export default AppBarCMS;
