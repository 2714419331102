import React, { useEffect, useState } from 'react';
import {
    Box, Drawer, Typography, FormGroup, FormControlLabel, Checkbox, Slider, IconButton, Collapse, TextField, Divider, Button, InputAdornment
} from '@mui/material';
import { ExpandMore, ExpandLess, Close, FilterList, ClearAll } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { GetDistinctSizes } from '../api/SizesAPI';
import { SelectDistinctflavors } from '../api/FlavorsAPI';

const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        drawerContent: {
            width: '250px',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            justifyContent: 'space-between',
        },
        filterSection: {
            marginBottom: theme.spacing(3),
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(10),
        },
        iconButton: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            borderRadius: '4px',
            padding: theme.spacing(1),
            textTransform: 'none',
        },
        priceInputs: {
            display: 'flex',
            gap: theme.spacing(10),
            marginTop: theme.spacing(1),
        },
        sectionTitle: {
            fontWeight: 'bold',
            fontSize: '14px !important',
            color: theme.palette.text.primary,
            textTransform: 'uppercase',
        },
        filterHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        input: {
            width: '90px',
            height: '50px',
            textAlign: 'right',
        },
        checkboxLabel: {
            fontSize: '14px',
            marginBottom: theme.spacing(-2.5),
        },
        formGroup: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
        },
        showMoreButton: {
            marginTop: theme.spacing(1),
            textTransform: 'none',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '4px',
            padding: theme.spacing(1),
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
    };
});

function FilterDrawer({ open, onClose, onFilterChange, priceRange, selectedFlavors, selectedSizes }) {
    const classes = useStyles();
    const [flavorOptions, setFlavorOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [tempSelectedFlavors, setTempSelectedFlavors] = useState(selectedFlavors);
    const [tempSelectedSizes, setTempSelectedSizes] = useState(selectedSizes);
    const [tempPriceRange, setTempPriceRange] = useState(priceRange);
    const [flavorsOpen, setFlavorsOpen] = useState(true);
    const [sizesOpen, setSizesOpen] = useState(true);
    const [priceOpen, setPriceOpen] = useState(true);
    const [showAllFlavors, setShowAllFlavors] = useState(false);
    const [showAllSizes, setShowAllSizes] = useState(false);

    useEffect(() => {
        const fetchFilterOptions = async () => {
            const flavorsResponse = await SelectDistinctflavors({});
            const sizesResponse = await GetDistinctSizes({});
            setFlavorOptions(flavorsResponse?.Dt || []);
            setSizeOptions(sizesResponse?.Dt || []);
        };
        fetchFilterOptions();
    }, []);

    const handleApplyFilters = () => {
        onFilterChange({
            type: 'apply',
            filters: {
                priceRange: tempPriceRange,
                flavors: tempSelectedFlavors,
                sizes: tempSelectedSizes,
            },
        });
        onClose();
    };

    const handleResetFilters = () => {
        setTempSelectedFlavors([]);
        setTempSelectedSizes([]);
        setTempPriceRange([0, 100]);
    };

    const handlePriceInputChange = (index, value) => {
        const newPriceRange = [...tempPriceRange];
        newPriceRange[index] = value;
        setTempPriceRange(newPriceRange);
    };

    const toggleShowAllFlavors = () => {
        setShowAllFlavors(prev => !prev);
    };

    const toggleShowAllSizes = () => {
        setShowAllSizes(prev => !prev);
    };

    const displayedFlavors = showAllFlavors ? flavorOptions : flavorOptions.slice(0, 20);
    const displayedSizes = showAllSizes ? sizeOptions : sizeOptions.slice(0, 20);

    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <Box className={classes.drawerContent}>
                <Box>
                    <Box className={classes.filterHeader}>
                        <Typography variant="subtitle1" sx={{ flexGrow: 1 }} className={classes.sectionTitle}>
                            <b>Filters</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" aria-label="close" onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>

                    <Box className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.iconButton}
                            onClick={handleResetFilters}
                            startIcon={<ClearAll />}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.iconButton}
                            onClick={handleApplyFilters}
                            startIcon={<FilterList />}
                        >
                            Apply
                        </Button>
                    </Box>

                    <br />

                    <Divider />
                    <Box className={classes.filterSection}>
                        <Box className={classes.filterHeader}>
                            <Typography className={classes.sectionTitle}><b>Price</b></Typography>
                            <IconButton onClick={() => setPriceOpen(!priceOpen)}>
                                {priceOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>

                        <Collapse in={priceOpen}>
                            <Slider
                                value={tempPriceRange}
                                onChange={(event, newValue) => setTempPriceRange(newValue)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={200}
                                sx={{ my: 2 }}
                            />
                            <Box className={classes.priceInputs}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={tempPriceRange[0]}
                                    onChange={(e) => handlePriceInputChange(0, parseInt(e.target.value, 10))}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        className: classes.input,
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={tempPriceRange[1]}
                                    onChange={(e) => handlePriceInputChange(1, parseInt(e.target.value, 10))}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        className: classes.input,
                                    }}
                                />
                            </Box>
                        </Collapse>
                    </Box>

                    <Divider />

                    <Box className={classes.filterSection}>
                        <Box className={classes.filterHeader}>
                            <Typography className={classes.sectionTitle}><b>Flavor</b></Typography>
                            <IconButton onClick={() => setFlavorsOpen(!flavorsOpen)}>
                                {flavorsOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={flavorsOpen}>
                            <FormGroup className={classes.formGroup}>
                                {displayedFlavors.map((flavor) => (
                                    <FormControlLabel
                                        key={flavor?.FlavorId}
                                        control={
                                            <Checkbox
                                                value={flavor?.FlavorId}
                                                checked={tempSelectedFlavors.includes(flavor?.FlavorId)}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    setTempSelectedFlavors(prev =>
                                                        checked ? [...prev, flavor?.FlavorId] : prev.filter(f => f !== flavor?.FlavorId)
                                                    );
                                                }}
                                            />
                                        }
                                        label={flavor?.FlavorName}
                                        className={classes.checkboxLabel}
                                    />
                                ))}
                            </FormGroup>
                            {flavorOptions.length > 20 && (
                                <>
                                <br />
                                <Button
                                    className={classes.showMoreButton}
                                    onClick={toggleShowAllFlavors}
                                    startIcon={showAllFlavors ? <ExpandLess /> : <ExpandMore />}
                                > 
                                    {showAllFlavors ? 'Show Less' : 'Show All'}
                                    </Button>
                                </>
                            )}
                        </Collapse>
                    </Box>

                    <Divider />

                    <Box className={classes.filterSection}>
                        <Box className={classes.filterHeader}>
                            <Typography className={classes.sectionTitle}><b>Size</b></Typography>
                            <IconButton onClick={() => setSizesOpen(!sizesOpen)}>
                                {sizesOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={sizesOpen}>
                            <FormGroup className={classes.formGroup}>
                                {displayedSizes.map((size) => (
                                    <FormControlLabel
                                        key={size?.SizeId}
                                        control={
                                            <Checkbox
                                                value={size?.SizeId}
                                                checked={tempSelectedSizes.includes(size?.SizeId)}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    setTempSelectedSizes(prev =>
                                                        checked ? [...prev, size?.SizeId] : prev.filter(s => s !== size?.SizeId)
                                                    );
                                                }}
                                            />
                                        }
                                        label={size?.SizeName}
                                        className={classes.checkboxLabel}
                                    />
                                ))}
                            </FormGroup>
                            {sizeOptions.length > 20 && (
                                <>
                                    <br />
                                <Button
                                    className={classes.showMoreButton}
                                    onClick={toggleShowAllSizes}
                                    startIcon={showAllSizes ? <ExpandLess /> : <ExpandMore />}
                                > 
                                    {showAllSizes ? 'Show Less' : 'Show All'}
                                    </Button>
                                    </>
                            )}
                        </Collapse>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
}

export default FilterDrawer;
