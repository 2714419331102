import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { GetCategorys } from '../api/CategoriesApis';
import { GetItems } from '../api/ItemsAPI';
import ProductCard from '../components/ProductCard';
import { toCamelCase } from '../components/GeneralFunctions';
import AppConfig from '../AppSettings';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@material-ui/core';
import {Typography } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff5722',
        },
        background: {
            paper: '#f3f4f6',
        },
        text: {
            primary: '#212121',
            secondary: '#757575',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f5f5f5',
                },
                indicator: {
                    backgroundColor: '#ff5722',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#ff5722',
                    },
                },
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    containerWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%', // Ensure the wrapper takes full width
    },
    container: {
        display: 'flex',
        overflowX: 'auto',
        padding: '20px 0',
        whiteSpace: 'nowrap', // Prevent wrapping
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        touchAction: 'pan-y', // Enable vertical panning to avoid interference with horizontal scrolling
    },
    productCard: {
        flex: '0 0 auto', // Ensures the cards stay in a row and don't wrap
        marginRight: 16, // Adds some space between the cards
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        display: 'block',
    },
    leftButton: {
        left: 0,
    },
    rightButton: {
        right: 0,
    },
    shopAllCard: {
        backgroundColor: 'black',
        color: 'white',
        display: 'inline-flex', // Change from flex to inline-flex
        justifyContent: 'center',
        alignItems: 'center',
        height: 434, // Adjust the height as needed
        minWidth: 300, // Ensure minimum width
        maxWidth: 300, // Ensure minimum width
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
}));
const cardStyle = {
    position: 'relative',
    background: 'linear-gradient(145deg, black, gray)',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxheight: '350px',
    minheight: '350px',
    minWidth: '250px',
    maxWidth: '250px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    borderRadius: '20px', // Slightly increased border radius
    boxShadow: '15px 15px 30px rgba(0, 0, 0, 0.5), -15px -15px 30px rgba(255, 255, 255, 0.2)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    overflow: 'hidden',
    padding: '20px',
    marginLeft: '5px'
};

// Add hover effect for 3D illusion
const cardHoverStyle = {
    ...cardStyle,
    transform: 'scale(1.05) translateY(-10px)', // Slight scale and lift
    boxShadow: '20px 20px 40px rgba(0, 0, 0, 0.6), -20px -20px 40px rgba(255, 255, 255, 0.3)',
};

// Inline styles for the text
const textStyle = {
    marginBottom: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '20px'
};

// Inline styles for the button
const buttonStyle = {
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '12px 25px', // Slightly increased padding
    backgroundColor: 'rgb(90,151,145)',
    color: 'white',
    border: 'none',
    borderRadius: '10px', // Slightly more rounded corners
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontWeight: 'bold',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)', // Added shadow for 3D effect
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
};

// Inline styles for the count
const CountStyle = { 
    position: 'absolute',
    top: '15px',
    left: '20%',
    transform: 'translateX(-50%)', 
    //color: 'rgb(90,151,145)',
    border: '1',
    borderRadius: '10px', // Slightly more rounded corners
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',  
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)', // Added shadow for 3D effect
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
};

// Arrow style
const arrowStyle = {
    marginLeft: '8px',
    fontSize: '1rem',
};

// Add hover effect for button
const buttonHoverStyle = {
    ...buttonStyle,
    transform: 'translateX(-50%) scale(1.1)', // Slightly enlarges on hover
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)', // Enhanced shadow on hover
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function ShopByCat() {
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyles(theme); // Pass the theme to useStyles
    const [value, setValue] = useState(0);
    const [itemsCount, setItemsCount] = useState(0);
    const [categories, setCategories] = useState([]);
    const [ItemCatShop, setItems] = useState([]);
    const containerRef = useRef(null);
    const [hover, setHover] = React.useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const fetchCategoriesData = async () => {
            try {
                const response = await GetCategorys({ IsCatVisible: true });
                setCategories(response?.Dt || []);
                if (response?.Dt?.length > 0) {
                    const RespItems = await fetchItemsByCategory(response.Dt[0].CatId);
                    setItemsCount(RespItems?.Dt?.length)
                    setItems(RespItems?.Dt?.slice(0,5) || []);
                }
            } catch (error) {
                console.error('Failed to fetch categories', error);
            }
        };

        fetchCategoriesData();
    }, []);

    const fetchItemsByCategory = async (categoryId) => {
        try {
            const response = await GetItems({
                Category: {
                    CatId: categoryId.toString()
                }, IsItemVisible: true
            });
            return response;
        } catch (error) {
            console.error('Failed to fetch items', error);
            return [];
        }
    };

    const handleChange = async (event, newValue) => {
        setValue(newValue);
        const RespItems = await fetchItemsByCategory(categories[newValue].CatId);
        setItemsCount(RespItems?.Dt?.length)
        setItems(RespItems?.Dt?.slice(0, 5) || []);
    };

    const handleChangeIndex = async (index) => {
        setValue(index);
        const RespItems = await fetchItemsByCategory(categories[index].CatId);
        setItemsCount(RespItems?.Dt?.length)
        setItems(RespItems?.Dt?.slice(0, 5) || []);
    };

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: -300, // Increase the value to scroll more
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: 300, // Increase the value to scroll more
                behavior: 'smooth',
            });
        }
    };

    let startX;
    let lastMoveX;
    let isScrolling = false;
    let lastScrollLeft;
    let scrollStartTime;
    const scrollLimit = 300;

    const handleTouchStart = (event) => {
        const touch = event.touches[0];
        startX = touch.clientX;
        lastMoveX = startX;
        isScrolling = true;
        lastScrollLeft = containerRef.current.scrollLeft;
        scrollStartTime = Date.now();

        // Remove fade-out effect on start
        containerRef.current.classList.remove('fade-out');
    };

    const handleTouchMove = (event) => {
        if (!isScrolling) return;

        const touch = event.touches[0];
        const deltaX = touch.clientX - lastMoveX;
        lastMoveX = touch.clientX;

        if (containerRef.current) {
            let totalScrolledDistance = containerRef.current.scrollLeft - lastScrollLeft;
            const remainingScroll = scrollLimit+15 - Math.abs(totalScrolledDistance);

            const scrollAmount = Math.min(remainingScroll, Math.abs(deltaX));

            containerRef.current.scrollLeft -= (deltaX > 0 ? scrollAmount : -scrollAmount);
        }
    };

    const handleTouchEnd = () => {
        if (!isScrolling) return;
        isScrolling = false;

        const scrollEndTime = Date.now();
        const scrollDistance = containerRef.current.scrollLeft - lastScrollLeft;
        const timeElapsed = scrollEndTime - scrollStartTime;
        const speed = scrollDistance / timeElapsed;

        let velocity = speed * 20; // Adjust the multiplier for more natural inertia
        const decelerationFactor = 0.95;

        const performInertiaScroll = () => {
            let totalScrolledDistance = containerRef.current.scrollLeft - lastScrollLeft;

            if (
                containerRef.current &&
                Math.abs(velocity) > 0.1 &&
                Math.abs(totalScrolledDistance) < scrollLimit
            ) {
                const remainingScroll = scrollLimit - Math.abs(totalScrolledDistance);
                const scrollAmount = Math.min(remainingScroll, Math.abs(velocity));

                containerRef.current.scrollLeft += (velocity > 0 ? scrollAmount : -scrollAmount);
                velocity *= decelerationFactor;

                // Apply fade-out effect as velocity decreases
                if (Math.abs(velocity) < 2) {
                    containerRef.current.classList.add('fade-out');
                }

                requestAnimationFrame(performInertiaScroll);
            } else {
                // Reset fade effect after scrolling stops
                containerRef.current.classList.remove('fade-out');
            }
        };

        performInertiaScroll();

        // Return handlers to attach to the container element
        return {
            handleTouchStart,
            handleTouchMove,
            handleTouchEnd,
        };
    };

    // Return handlers to attach to the container element
    
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: 'background.paper', width: '100%', mx: 'auto' }}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        allowScrollButtonsMobile
                    >
                        {categories.map((category, index) => (
                            <Tab key={category.CatId} label={category.CatNameFirstLang} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {categories.map((category, index) => (
                        <TabPanel key={category.CatId} value={value} index={index} dir={theme.direction}>
                            <div className={classes.containerWrapper}>
                                <IconButton style={{ display: isSmallScreen ? 'none' : 'block' }}
                                    className={`${classes.scrollButton} ${classes.leftButton}`}
                                    onClick={scrollLeft}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <div
                                    className={classes.container}
                                    ref={containerRef}
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                >
                                    {/*<div className={classes.shopAllCard} onClick={() => navigate(`/ProductListByCat/${category?.CatNameFirstLang.replace(/\s/g, '-') }`)}>*/}
                                    {/*    SHOP ALL {category?.CatNameFirstLang}*/}
                                    {/*</div>*/}

                                    <div
                                        style={hover ? cardHoverStyle : cardStyle}
                                        onClick={() => navigate(`/ProductListByCat/${category?.CatNameFirstLang.replace(/\s/g, '-')}`)}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                    >
                                        <Typography variant="body2" color="textprimary" style={CountStyle}> 
                                            {itemsCount} products
                                        </Typography> 
                                        <div style={textStyle}>
                                            {category?.CatNameFirstLang}
                                        </div>
                                        <button style={hover ? buttonHoverStyle : buttonStyle}>
                                            Shop All {toCamelCase(category?.CatNameFirstLang)}
                                            <span style={arrowStyle}>→</span> {/* Arrow symbol */}
                                        </button>
                                    </div>
                                    {ItemCatShop.map((product) => (
                                        <ProductCard
                                            product={product}
                                            key={product.ItemId}
                                            brand={product.BrandName}
                                            image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}
                                            name={product.ItemName}
                                            description={product.ItemDescription}
                                            price={product.ItemPrice}
                                            onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}
                                        />
                                    ))}
                                </div>
                                <IconButton style={{ display: isSmallScreen ? 'none' : 'block' }}
                                    className={`${classes.scrollButton} ${classes.rightButton}`}
                                    onClick={scrollRight}
                                >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </div>
                        </TabPanel>
                    ))}
                </SwipeableViews>
            </Box>
        </ThemeProvider>
    );
}
