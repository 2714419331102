import AppConfig from '../AppSettings';


export const GetLinks = async (data) => {
    try {

        // Create a unique cache key based on the request parameters
        const cacheKey = `GetLinks_${JSON.stringify(data)}`;

        // Check if the data is already in the cache
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
            // Return cached data if available
            return JSON.parse(cachedData);
        }


        const response = await fetch(AppConfig.apiUrl + `/Links/GetLinks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();


        // Store the result in the sessionStorage cache
        sessionStorage.setItem(cacheKey, JSON.stringify(result));

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
 

