import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, Box, Avatar, Button, Modal, TextField, DialogActions, DialogContent } from '@mui/material';
import { deepOrange, lightBlue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { GetLinks } from '../api/LinksAPI';
import { InsertComent } from '../api/GetInTouchAPI';
import { Helmet } from 'react-helmet';

// Styled components
const Header = styled('header')(({ theme }) => ({
    background: `linear-gradient(135deg, ${deepOrange[500]} 0%, ${lightBlue[500]} 100%)`,
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
    borderBottom: `8px solid ${lightBlue[700]}`,
    marginBottom: theme.spacing(5),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

const Section = styled('section')(({ theme }) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: '#f4f4f4',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    color: deepOrange[500],
    textAlign: 'center',
}));

const TeamMember = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(3),
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
    width: 120,
    height: 120,
    margin: 'auto',
    border: `4px solid ${deepOrange[500]}`,
}));

const ContactInfo = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(6),
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
    backgroundColor: deepOrange[500],
    color: '#fff',
    '&:hover': {
        backgroundColor: deepOrange[700],
    },
}));

const ContactFormModal = ({ open, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        if (!formData.message) {

            alert('Please fill message field')
            return
        }
        InsertComent({
            name: formData.name,
            email: formData.email,
            message: formData.message,
        });
        setFormData({
            name: '',
            email: '',
            message: '',
        });
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="contact-form-title"
            aria-describedby="contact-form-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" component="h2" id="contact-form-title">
                    Get in Touch
                </Typography>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Message"
                        name="message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
};

const AboutUs = () => {
    const [links, setLinks] = useState({
        FbLink: '',
        InstaLink: '',
        TwitterLink: '',
        WhatsapNumber: '',
        LocationLink: '',
        PhoneNumber: '',
        EmailAddress: '',
    });
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetLinks({});
                const linkData = response.Dt.reduce((acc, link) => {
                    acc[link.LinkName] = link.LinkValue;
                    return acc;
                }, {});

                setLinks(linkData);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);

    const handleContactClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <Container><br /><br />
            <Helmet>
                <title>Energize - About-us</title>
            </Helmet>
            <Header>
                <Typography variant="h3" gutterBottom>
                    About Energize-lb
                </Typography>
                <Typography variant="h6">
                    The best supplement shop in Lebanon, your ultimate destination for premium gym supplements.
                </Typography>
            </Header>

            <Section>
                <Typography variant="h4" gutterBottom sx={{ color: 'rgb(90, 151, 145)', textAlign: 'center' }}>
                    Welcome to Energize-lb
                </Typography>
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify', marginX: 'auto', maxWidth: '800px' }}>
                    At Energize-lb, we are dedicated to providing top-quality protein and supplements to help you achieve your fitness goals. Based in Lebanon, we offer a wide range of products to support your workout regimen and overall health.
                </Typography>
            </Section>

            <Section>
                <SectionTitle variant="h4" gutterBottom>
                    Our Story
                </SectionTitle>
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify', marginX: 'auto', maxWidth: '800px' }}>
                    Energize-lb was founded with a mission to bring the best fitness supplements to Lebanon. Our journey began with a passion for health and fitness and a commitment to sourcing the highest-quality products. We are proud to serve our community and help individuals lead healthier, more active lives.
                </Typography>
            </Section>

            <Section>
                <SectionTitle variant="h4" gutterBottom>
                    Our Mission
                </SectionTitle>
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify', marginX: 'auto', maxWidth: '800px' }}>
                    Our mission is to empower our customers to reach their fitness goals by providing exceptional products and unparalleled customer service. We strive to be a trusted partner in your health journey, offering products that are effective, reliable, and backed by our expertise.
                </Typography>
            </Section>

            <Section>
                <SectionTitle variant="h4" gutterBottom>
                    Meet the Team
                </SectionTitle>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <TeamMember elevation={3}>
                            <AvatarStyled src="https://energizelb.originsbackend.online/Images/mhmdSawle.jpeg" />
                            <Typography variant="h6">Mohammad Sawli</Typography>
                            <Typography variant="body2">Founder & CEO</Typography>
                            <Typography variant="body1">
                                Mohammad is passionate about fitness and has over 10 years of experience in the supplement industry.
                            </Typography>
                        </TeamMember>
                    </Grid>
                </Grid>
            </Section>

            <Section >
                <SectionTitle variant="h4" gutterBottom >
                    Contact Us
                    </SectionTitle> 

                <Grid container spacing={6} justifyContent="center">
                <ContactInfo>
                    <Typography variant="body1">
                        <strong>Address:</strong> {links.RegionName}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Phone:</strong> {links.PhoneNumber}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Email:</strong> {links.EmailAddress}
                    </Typography>
                    <ButtonStyled variant="contained" onClick={handleContactClick}>
                        Get in Touch
                    </ButtonStyled>
                    </ContactInfo>
                </Grid>
            </Section>

            <ContactFormModal open={isModalOpen} onClose={handleCloseModal} />
        </Container>
    );
};

export default AboutUs;
