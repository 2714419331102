import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetItems } from '../api/ItemsAPI';
import AppConfig from '../AppSettings';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ShopByCat from './ShopByCategory';
import ShopByBrand from './ShopByBrand';
import OffersSectionAutoScroll from './OffersSection';
import ProductCard from '../components/ProductCard';
import Product_PD from './Product_PD';

import { Breadcrumbs, Grid, ButtonGroup, Button } from '@mui/material';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DumbleGIF from '../components/DumbleGIF';

const useStyles = makeStyles({

    container: {
        display: 'flex',
        overflowX: 'auto',
        marginTop:'-15px',
        paddingBottom: '30px',  // Changed padding to paddingBottom
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    containerNewArrival: {
        display: 'flex',
        overflowX: 'auto',
        //marginTop: '-15px',
        padding: '20px 0',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
});

const App = () => {


    const theme = useTheme();

    const classes = useStyles();

    const [products, setProducts] = useState([]);
    const [productsOffres, setProductsOffres] = useState([]); 
    const [productsOnSale, setProductsOnSale] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const navigate = useNavigate();

    const handleCardClick = (route) => {
        navigate(route);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                const paramsSelect = { IsItemIsNewArrival: true, IsItemVisible:true };
                const ItemsnewArrival = await GetItems(paramsSelect);

                const paramsSelectoffres = { IsItemOnOffre: true, IsItemVisible: true };
                const ItemsOffres = await GetItems(paramsSelectoffres); 

                const paramsSelectOnSale = { IsItemIsOnSale: true, IsItemVisible: true };
                const ItemsOnSale = await GetItems(paramsSelectOnSale);


                setProducts(ItemsnewArrival?.Dt);
                setProductsOnSale(ItemsOnSale?.Dt);
                setProductsOffres(ItemsOffres?.Dt); 
            } catch (error) {
                console.error('Failed to fetch data', error);
            } finally {
                setLoading(false); // Set loading to false
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className={classes.loader}>
                <DumbleGIF isLoading={loading}></DumbleGIF>
            </div>
        );
    }
    const handleOffreImageClick = (product) => {
        navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)
    };


    return (
        <div>
            {productsOffres?.length > 0 && (
                <>
                    <h1 className="NewArrivalGenericTitleCSS">Offres</h1>
                    <div className={classes.container}>

                        <OffersSectionAutoScroll Products={productsOffres} onImageClick={handleOffreImageClick} />
                        {/*{productsOffres.map((product, index) => (*/}
                        {/*    <ProductCard*/}
                        {/*        product={product}*/}
                        {/*        key={index}*/}
                        {/*        brand={product.BrandName}*/}
                        {/*        image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}*/}
                        {/*        name={product.ItemName}*/}
                        {/*        description={product.ItemDescription}*/}
                        {/*        price={product.ItemPrice}*/}
                        {/*        onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}*/}
                        {/*    />*/}
                        {/*))}*/}
                    </div>
                </>
            )}

            {productsOnSale?.length > 0 && (
                <>
                    <h1 className="NewArrivalGenericTitleCSS">On Sale</h1>
                    <div className={classes.container}>
                        {productsOnSale.map((product, index) => (
                            <ProductCard
                                product={product}
                                key={index}
                                brand={product.BrandName}
                                image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}
                                name={product.ItemName}
                                description={product.ItemDescription}
                                price={product.ItemPrice}
                                onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}
                            />
                        ))}
                    </div>
                </>
            )}

            {products?.length > 0 && (
                <>
                    <h1 className="NewArrivalGenericTitleCSS">NEW ARRIVALS</h1>
                    <Typography
                        style={{
                            textAlign: 'center',
                            backgroundColor: 'black',
                            color: 'whitesmoke',
                            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)', // Increased shadow for more depth
                            borderRadius: '10px', // Rounded corners for a smooth design
                            fontWeight: 'bold', // Bold text for emphasis 
                            letterSpacing: '0.5px', // Adds spacing between letters for a modern touch
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Subtle shadow to lift the text off the page
                            padding: '5px 10px', // Minimal padding to keep the border close to the text
                            display: 'inline-block', // Makes the border fit closely around the text
                            maxWidth: '95%', // Ensures the element doesn't exceed its container width
                        }}
                    >
                        Shop the latest and greatest innovation for supplements, gear...
                    </Typography>



                    <div className={classes.containerNewArrival}>
                        {products.map((product, index) => (
                            <ProductCard
                                product={product}
                                key={index}
                                brand={product.BrandName}
                                image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}
                                name={product.ItemName}
                                description={product.ItemDescription}
                                price={product.ItemPrice}
                                onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}
                            />
                        ))}
                    </div>
                </>
            )}

            <h1 className="NewArrivalGenericTitleCSS">SHOP BY CATEGORY</h1>
            <div>
                <ThemeProvider theme={theme}>
                    <Grid item xs={12} md={6}>
                        <ShopByCat />
                    </Grid>
                </ThemeProvider>
            </div>
            <br/>
            <br/>
         <h1 className="NewArrivalGenericTitleCSS">SHOP BY BRAND</h1>
            <div>
                <ThemeProvider theme={theme}>
                    <Grid item xs={12} md={6}>
                        <ShopByBrand />
                    </Grid>
                </ThemeProvider>
            </div>
        </div>
    );
};

export default App;
