import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Container, Snackbar, IconButton, InputAdornment, CircularProgress, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import AppConfig from '../AppSettings';
import { EncryptString } from '../components/GeneralFunctions';
import { ChangePassword } from '../api/authentication';

const ChangePasswordForm = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'currentPassword') setCurrentPassword(value);
        else if (name === 'newPassword') {
            setNewPassword(value);
            validatePasswordStrength(value);
        } else if (name === 'confirmNewPassword') setConfirmNewPassword(value);
    };

    const validatePasswordStrength = (password) => {
        if (password.length < 6) {
            setPasswordStrength('Weak');
        } else if (password.length < 10) {
            setPasswordStrength('Medium');
        } else {
            setPasswordStrength('Strong');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!currentPassword || !newPassword || !confirmNewPassword) {
            setError('Please fill in all fields.');
            return;
        }
        if (newPassword === currentPassword) {
            setError('New password should be different from the current password.');
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const StrSendToAPICurrentPassword = await EncryptString(currentPassword);
            const StrSendToAPINewPassword = await EncryptString(newPassword);
            const StrSendToAPIconfirmNewPassword = await EncryptString(confirmNewPassword);

            const DashboardParams = {
                UserId: localStorage.getItem("UserId"),
                CurrentPassword: StrSendToAPICurrentPassword,
                NewPassword: StrSendToAPINewPassword,
                ConfirmNewPassword: StrSendToAPIconfirmNewPassword
            };

            const response = await ChangePassword(DashboardParams);

            setLoading(false);

            if (!response?.Error?.Message) {
                setSuccessMessage('Password changed successfully.');
                setError('');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                setPasswordStrength('');
            } else {
                setErrorMessage('Failed to change password.' + response?.Error?.Message);
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
            setLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage('');
        setErrorMessage('');
    };

    const handleToggleShowPassword = (field) => {
        switch (field) {
            case 'current':
                setShowCurrentPassword(!showCurrentPassword);
                break;
            case 'new':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirm':
                setShowConfirmNewPassword(!showConfirmNewPassword);
                break;
            default:
                break;
        }
    };

    const containerStyles = {
        position: 'absolute',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    };

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        pointerEvents: loading ? 'auto' : 'none',
    };

    return (
        <Container maxWidth="sm">
            <Helmet>
                <title>Change Password</title>
            </Helmet>

            {loading && <div style={overlayStyles}></div>}
            <div style={containerStyles}>
                {loading && (
                    <CircularProgress
                        size={75}
                        thickness={5}
                        style={{ color: 'white' }}
                        aria-label="Loading"
                    />
                )}
            </div>

            <form onSubmit={handleSubmit}>
                <Typography variant="h6">Change Password</Typography>
                {error && <Typography color="error">{error}</Typography>}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="currentPassword"
                            label="Current Password"
                            type={showCurrentPassword ? 'text' : 'password'}
                            name="currentPassword"
                            value={currentPassword}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleToggleShowPassword('current')}>
                                            {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="newPassword"
                            label="New Password"
                            type={showNewPassword ? 'text' : 'password'}
                            name="newPassword"
                            value={newPassword}
                            onChange={handleChange}
                            error={passwordStrength === 'Weak'}
                            helperText={passwordStrength === 'Weak' && 'Password is weak'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleToggleShowPassword('new')}>
                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="confirmNewPassword"
                            label="Confirm New Password"
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={handleChange}
                            error={newPassword !== '' && confirmNewPassword !== '' && newPassword !== confirmNewPassword}
                            helperText={newPassword !== '' && confirmNewPassword !== '' && newPassword !== confirmNewPassword && 'Passwords do not match'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleToggleShowPassword('confirm')}>
                                            {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <br />
                <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>Change Password</Button>
            </form>
            <Snackbar open={!!successMessage || !!errorMessage} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={successMessage ? 'success' : 'error'} elevation={6} variant="filled">
                    {successMessage || errorMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ChangePasswordForm;
