import AppConfig from '../AppSettings';

export const GetCategorys = async (data) => {
    try{ 

        //called from shop from categury as well
    const token = localStorage.getItem("jwt");
        const response = await fetch(AppConfig.apiUrl+`/Categories/GetCat`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                //'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        }) 
        const result =await response.json();
        return result;
    } catch (error) {  
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}

export const InsertCategory = async (data) => {  
     
    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Categories/InsertCat`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();  
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
}
export const UpdateCategorys = async (data) => { 
    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Categories/UpdateCat`,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();
        return result;
    }
    catch(error){
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
} 

export const DeleteCategory = async (data) => {

    const token = localStorage.getItem("jwt");
    try{
        const response = await fetch(AppConfig.apiUrl+`/Categories/DeleteCat`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result =await response.json();
        return result;
    }catch(error){ 
        const result = {
            Error: {
                Message: error
            }}
        return result;
    }
} 