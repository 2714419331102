import axios from 'axios'
import AppConfig from '../AppSettings';
 
export const GetItems_Filter = async (data) => {
     
    try {
        const response = await fetch(AppConfig.apiUrl + `/Items/GetItems_Filter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        return result;
    } catch (error) {
         
        const result = {
            Error: {
                Message: error
            }
        } 
        return result;
    }
}
export const GetItemsForSearch = async (data) => {
     
    try {
        const response = await fetch(AppConfig.apiUrl + `/Items/GetItemsForSearch`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        return result;
    } catch (error) {
         
        const result = {
            Error: {
                Message: error
            }
        } 
        return result;
    }
}
export const GetItems = async (data) => {

    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Items/GetItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        return result;
    } catch (error) { 
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
} 



export const InsertItems = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Items/InsertItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const UpdateItems = async (data) => { 
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Items/UpdateItems`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const DeleteItems = async (data) => {

    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Items/DeleteItems`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

