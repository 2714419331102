import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Drawer, Fab, Badge, Box } from '@mui/material';
import { AddShoppingCart, CheckCircle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import ProductPD from '../pages/Product_PD'; 
import { GetItems } from '../api/ItemsAPI';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles({
   
    mediaContainer: {
        //when i uncomment them it overrtide the brand name
        /*position: 'relative',display:'block'*/
    },
    content: {
        marginTop: '-50px',
        textAlign: 'center',
    },
    iconButton: {
        color: 'rgb(90, 151, 145)', 
        position: 'absolute !importnat',
        bottom: 60,
        left: 10,
        padding: 8,
        borderRadius: '50%',
        animation: '$iconAnimation 1s ease',
    },
    '@keyframes iconAnimation': {
        '0%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(1.5)' },
        '100%': { transform: 'scale(1)' },
    },
    success: {
        color: 'green',
        animation: '$successAnimation 1s ease',
    },
    '@keyframes successAnimation': {
        '0%': { transform: 'scale(0)' },
        '100%': { transform: 'scale(1)' },
    },
    drawerContent: {
        width: '100%',
        maxWidth:'350px',
        MinWidth:'300px',
        height: '80px'
    },
    saleBadge: {
        position: 'absolute',
        top: 10,
        left: 10,
        backgroundColor: 'white',
        color: 'rgb(90,151,145)',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '12px',
        zIndex: 1,
    }, 
    OutOfStock: {
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: 'white',
        color: 'red',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '12px',
        zIndex: 1,
    }, 
    originalPrice: { 
        
        textDecoration: 'line-through',
        color: 'grey',
        marginRight: '10px',
    },
});

const ProductCard = ({ product, image, name, description, price, brand, onCardClick,SmallCard }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [added, setAdded] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');


    const card= {
        minWidth: SmallCard?150 : 300,
        maxWidth: SmallCard ? 150 : 300,
        margin: '0 10px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        perspective: 1000,
        '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        },
    }
    const media= {
        height: SmallCard ? 150 : 300,
        width: SmallCard ? 150 : 300,
            transition: 'transform 0.3s',
        }

    const handleAddClick = () => {
        setAdded(true);
        setTimeout(() => setDrawerOpen(true), 1000); // Reset after 2 seconds 
        setTimeout(() => setAdded(false), 2000); // Reset after 2 seconds
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    }; 
    const brandNameClick = async (event, BrandName, Item) => { 
        event.stopPropagation(); 
        //const Itemsbybrand = await fetchItemsToSend({ BrandId: Item?.BrandId });
        //const ItemsToDisplay = Itemsbybrand?.Dt;
        navigate(`/ProductListByBrand/${BrandName.replace(/\s/g, '-')}`)
    };


    return (
        <>
            <Card style={card} onClick={onCardClick}>
                <div className={classes.mediaContainer}>
                    {product.IsItemIsOnSale && (
                        <div className={classes.saleBadge}>SALE</div>
                    )} 
                    {product.IsItemIsOutOfStock && ( 
                        <div className={classes.OutOfStock}>Out Of Stock</div>
                    )} 
                    <CardMedia
                        component="img"
                        style={media}
                        image={image}
                        title={name}
                    />
                    <IconButton
                        className={`${classes.iconButton} ${added ? classes.success : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAddClick();
                        }}
                    >
                        {added ? (
                            <Fab size="small" className="AddedCheckCircleCardBtn">
                                <CheckCircle />
                            </Fab>
                        ) : (
                            <Fab size="small" className="AddCardBtn">
                                <AddShoppingCart />
                            </Fab>
                        )}
                    </IconButton>
                </div>
                <CardContent className={classes.content}>
                    <Typography variant="body2" color="textSecondary"
                        onClick={(event) => {
                            event.stopPropagation();
                            brandNameClick(event, product?.BrandName, product);
                        }} >
                        <u>{product?.BrandName}</u>
                    </Typography>
                    <Typography className="ItemNameNewArrivalCSS">
                        {name}
                    </Typography>
                    {product.IsItemIsOnSale ? (
                        <Box display="flex" justifyContent="center" alignItems="center" gap={1}> 
                            <Typography className="ItemNameNewArrivalCSS">
                                <b>{price}</b>
                            </Typography> 
                            <Typography variant="body2" className={classes.originalPrice}>
                               {product.ItemPriceBeforeSale}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography className="ItemNameNewArrivalCSS">
                            <b>{price}</b>
                        </Typography>
                    )}
                </CardContent>
            </Card>

            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div className={classes.drawerContent} role="presentation">
                    <ProductPD ProductToCart={product} onclosefct={closeDrawer} />
                </div>
            </Drawer>
        </>
    );
};

export default ProductCard;
