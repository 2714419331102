import * as React from 'react';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled, alpha } from '@mui/material/styles'; 
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderRounded from '@mui/icons-material/FolderRounded';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import DetailsOutlinedIcon from '@mui/icons-material/DetailsOutlined';
import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EmojiFoodBeverageOutlinedIcon from '@mui/icons-material/EmojiFoodBeverageOutlined';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Checkbox,
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { Drawer } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'; 
const ITEMS = [
    {
        id: '1.1',
        label: 'Management',
        fileType: 'Mng',
        children: [
            { id: '1.1.1', label: 'Categories', fileType: 'Cat', OnClick: 'Categories' },
            { id: '1.1.2', label: 'Sub-Categories', fileType: 'SubCat', OnClick: 'sub-categories' },
            { id: '1.1.3', label: 'Items', fileType: 'Item', OnClick: 'Items' },
            { id: '1.1.4', label: 'Brands', fileType: 'Brand', OnClick: 'Brands' },
            { id: '1.1.5', label: 'Flavors', fileType: 'Flavor', OnClick: 'Flavors' },
            { id: '1.1.6', label: 'Sizes', fileType: 'Size', OnClick: 'Sizes' },
            { id: '1.1.7', label: 'Get In Touch Messages', fileType: 'Cmnt', OnClick: 'DisplayGetInTouch' },
        ],
    },
    //{ id: '1.2', label: 'Personal', fileType: 'folder' },
    //{ id: '1.3', label: 'Group photo', fileType: 'image' },
];

//const ITEMS = [
//    {
//        id: '1',
//        label: 'Documents',
//        children: [
//            {
//                id: '1.1',
//                label: 'Company',
//                children: [
//                    { id: '1.1.1', label: 'Invoice', fileType: 'pdf' },
//                    { id: '1.1.2', label: 'Meeting notes', fileType: 'doc' },
//                    { id: '1.1.3', label: 'Tasks list', fileType: 'doc' },
//                    { id: '1.1.4', label: 'Equipment', fileType: 'pdf' },
//                    { id: '1.1.5', label: 'Video conference', fileType: 'video' },
//                ],
//            },
//            { id: '1.2', label: 'Personal', fileType: 'folder' },
//            { id: '1.3', label: 'Group photo', fileType: 'image' },
//        ],
//    },
//    {
//        id: '2',
//        label: 'Bookmarked',
//        fileType: 'pinned',
//        children: [
//            { id: '2.1', label: 'Learning materials', fileType: 'folder' },
//            { id: '2.2', label: 'News', fileType: 'folder' },
//            { id: '2.3', label: 'Forums', fileType: 'folder' },
//            { id: '2.4', label: 'Travel documents', fileType: 'pdf' },
//        ],
//    },
//    { id: '3', label: 'History', fileType: 'folder' },
//    { id: '4', label: 'Trash', fileType: 'trash' },
//];
function DotIcon() {
    return (
        <Box
            sx={{
                width: 6,
                height: 6,
                borderRadius: '70%',
                bgcolor: 'warning.main',
                display: 'inline-block',
                verticalAlign: 'middle',
                zIndex: 1,
                mx: 1,
            }}
        />
    );
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color:
        theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[400],
    position: 'relative',
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: theme.spacing(3.5),
    },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    flexDirection: 'row-reverse',
    borderRadius: theme.spacing(0.7),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
    [`&.Mui-expanded `]: {
        '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.dark,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '16px',
            top: '44px',
            height: 'calc(100% - 48px)',
            width: '1.5px',
            backgroundColor:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
        },
    },
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
        backgroundColor:
            theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
        },
    });

    return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
    color: 'inherit',
    fontSize: '1.0rem',
    fontFamily: 'General Sans',
    fontWeight: 500,
});

function CustomLabel({ icon: Icon, expandable, children, ...other }) {
    return (
        <TreeItem2Label
            {...other}
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {Icon && (
                <Box
                    component={Icon}
                    className="labelIcon"
                    color="inherit"
                    sx={{ mr: 1, fontSize: '1.5rem' }}
                />
            )}

            <StyledTreeItemLabelText variant="body2">{children}</StyledTreeItemLabelText>
            {expandable && <DotIcon />}
        </TreeItem2Label>
    );
}

const isExpandable = (reactChildren) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

const getIconFromFileType = (fileType) => {
    switch (fileType) {
        case 'image':
            return ImageIcon;
        case 'pdf':
            return PictureAsPdfIcon;
        case 'doc':
            return ArticleIcon;
        case 'video':
            return VideoCameraBackIcon;
        case 'folder':
            return FolderRounded;
        case 'pinned':
            return FolderOpenIcon;
        case 'trash':
            return DeleteIcon;
        case 'Cat':
            return CategoryOutlinedIcon;
        case 'SubCat':
            return ScatterPlotOutlinedIcon;
        case 'Item':
            return DragIndicatorOutlinedIcon; 
        case 'Size':
            return FullscreenExitOutlinedIcon;
        case 'Flavor':
            return EmojiFoodBeverageOutlinedIcon;
        case 'Brand': 
            return LoyaltyOutlinedIcon;
        case 'Mng': 
            return AdminPanelSettingsOutlinedIcon;
        case 'Cmnt': 
            return FeedbackOutlinedIcon;
        default:
            return ArticleIcon;
    }
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { id, itemId, label, disabled, children, onClick, ...other } = props;
    const navigate = useNavigate(); // Use useNavigate hook to handle navigation

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getCheckboxProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);
    let icon;
    if (item.fileType) {
        icon = getIconFromFileType(item.fileType);
    }

    const handleClick = () => {
        if (item?.OnClick) {
            navigate(item.OnClick);
            if (props.toggleDrawerHandler) {
                props.toggleDrawerHandler(); // Close drawer after navigation
            }
        }
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <StyledTreeItemRoot {...getRootProps(other)} onClick={handleClick}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-selected': status.selected,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <TreeItem2Checkbox {...getCheckboxProps()} />
                    <CustomLabel
                        {...getLabelProps({ icon, expandable: expandable && status.expanded })}
                    />
                 
                </CustomTreeItemContent>

                {children && <TransitionComponent {...getGroupTransitionProps()} />}
            </StyledTreeItemRoot>
        </TreeItem2Provider>

    );
});

export default function FileExplorer(props) {
    return (
        <Drawer open={props.open} onClose={props.toggleDrawerHandler} PropsCountsMyCat_SubCat_Item_COUNT={props.PassCounts}>
            <RichTreeView
                items={ITEMS}
                defaultExpandedItems={['1', '1.1']}
                defaultSelectedItems="1.1"
                sx={{ height: 'fit-content', flexGrow: 1, width: 300, maxWidth: 800, overflowY: 'auto' }}
                slots={{ item: (itemProps) => <CustomTreeItem {...itemProps} toggleDrawerHandler={props.toggleDrawerHandler} /> }}
            />
        </Drawer>
    );
}
