import React, { useState, useEffect, } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import HomeCMS from './pages/HomeCMS';
import LogIn from './pages/Login'; 

function App() {

    const [isAuthorized, setIsAuthorized] = useState()
    const [open, setOpen] = useState(false);

    const auth = async () => {
        const JWT = localStorage.getItem("jwt");
        if (JWT) {
            setIsAuthorized(true)
        } else {
            setIsAuthorized(false)
        }
    }
    useEffect(() => {

        //clear session storage on refresh the page
        sessionStorage.clear();

        auth()
    }, [])
    return (
        <Router>
            <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="/CMS/*" element={<HomeCMS isAuthorized={isAuthorized} />} />
                <Route path='/LogIn' element={ <LogIn setIsAuthorized={setIsAuthorized} handleClick={() => setOpen(true)} />}  /> 
            </Routes>
        </Router>
    );
}

export default App;
