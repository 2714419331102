import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, IconButton, Drawer, Fab, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, Slider, Menu, Divider } from '@mui/material';
import { FilterList, Sort, AddShoppingCart, CheckCircle } from '@mui/icons-material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AppConfig from '../AppSettings';
import ProductCardFromList from '../components/ProductCardFromList';
import { GetItems, GetItems_Filter } from '../api/ItemsAPI';
import FilterDrawer from '../components/FilterDrawer'; 
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'; 
import { Helmet } from 'react-helmet'; 
import DumbleGIF from '../components/DumbleGIF';

const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff5722',
        },
        background: {
            paper: '#f3f4f6',
            default: '#fafafa',
        },
        text: {
            primary: '#212121',
            secondary: '#757575',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h6: {
            fontWeight: 600,
            fontSize: '1.25rem', // Increase font size for h6
        },
        body2: {
            color: '#757575',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 400,
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: '#673ab7',
                },
                thumb: {
                    height: 24,
                    width: 24,
                    backgroundColor: '#fff',
                    border: '2px solid currentColor',
                    '&:hover': {
                        boxShadow: '0 0 0 8px rgba(103, 58, 183, 0.16)',
                    },
                    '& .MuiSlider-valueLabel': {
                        left: 'calc(-50% + 4px)',
                    },
                },
                valueLabel: {
                    left: 'calc(-50% + 4px)',
                    top: -22,
                    '& *': {
                        background: 'transparent',
                        color: '#000',
                    },
                },
            },
        },
    },
});

const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        root: {
            background: 'linear-gradient(to right, #f8f9fa, #e0e0e0)',
            minHeight: '100vh',
            padding: theme.spacing(2),
        },
        productCard: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        cardMedia: {
            height: 'auto', // Set a fixed height
            objectFit: 'fill', // Ensure the image covers the area
        },
        cardContent: {
            marginTop: '-60px',
            flexGrow: 1,
        },
        productTitle: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        iconButton: {
            color: 'rgb(90, 151, 145)',
            backgroundColor: 'rgb(90,151,145)',
            position: 'absolute',
            bottom: 50,
            left: -60,
            borderRadius: '50%',
            animation: '$iconAnimation 1s ease',
        },
        '@keyframes iconAnimation': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.5)' },
            '100%': { transform: 'scale(1)' },
        },
        success: {
            color: 'green',
            animation: '$successAnimation 1s ease',
        },
        '@keyframes successAnimation': {
            '0%': { transform: 'scale(0)' },
            '100%': { transform: 'scale(1)' },
        },
        drawerContent: {
            minWidth: '300px',
            width: '100%',
            padding: theme.spacing(2),
        },
        filterButton: {
            fontSize:'12px !important',
            marginRight: theme.spacing(2),
        }, loader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        },
    };
});

function ProductList() {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const location = useLocation();

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [sortDrawerOpen, setSortDrawerOpen] = useState(false);
    const { ItemsToDisplay } = location.state || {};
    const [added, setAdded] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [pageTitle, setpageTitle] = useState('');
    const [priceRange, setPriceRange] = useState([0, 100]);
    const [selectedFlavors, setSelectedFlavors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [filtersCleared, setFiltersCleared] = useState(false); // Track if filters are cleared
    const [loading, setLoading] = useState(true); // Add loading state 
    const [anchorEl, setAnchorEl] = useState(null);

    // Add a state to store the current filters
    const [currentFilters, setCurrentFilters] = useState({
        priceRange: [0, 100],
        flavors: [],
        sizes: []
    });
    const [filters, setFilters] = useState({
        priceRange: [0, 100],
        flavors: [],
        sizes: [],
    });


    const toggleFilterDrawer = (open) => () => {
        setFilterDrawerOpen(open);
    };

    const toggleSortDrawer = (open) => () => {
        setSortDrawerOpen(open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true before API call

                const fetchItems = async () => {
                    const pathSegments = location.pathname.split('/');
                    const isProductListByBrand = location.pathname.includes("ProductListByBrand");
                    const isProductListByCat = location.pathname.includes("ProductListByCat");


                    if (isProductListByCat) {
                        const Name = pathSegments[pathSegments.length - 1].replace(/-/g, ' ');

                        const paramsSelectByCat = {
                            category: { CatNameFirstLang: Name }
                            , IsItemVisible: true
                        };

                        const ItemsResp = await GetItems( paramsSelectByCat );
                        setpageTitle(Name)
                        setItems(ItemsResp?.Dt);
                    }
                    else if (isProductListByBrand) {
                        const Name = pathSegments[pathSegments.length - 1].replace(/-/g, ' ');
                        const ItemsResp = await GetItems({ BrandName: Name, IsItemVisible: true });
                        setpageTitle(Name)
                        setItems(ItemsResp?.Dt);
                    } else {
                        const SubCatNameFromURL = pathSegments[pathSegments.length - 1].replace(/-/g, ' ');
                        const ItemsResp = await GetItems({ SubCatNameFirstLang: SubCatNameFromURL, IsItemVisible: true });
                        setpageTitle(SubCatNameFromURL)
                        setItems(ItemsResp?.Dt);
                    }
                };

                await fetchItems(); // Fetch items
                if (filtersCleared) { 
                    await fetchItems(); // Refetch the original items when filters are cleared
                    setFiltersCleared(false); // Reset the state
                }

            } catch (error) {
                console.error('Error fetching items:', error);
            } finally {
                setLoading(false); // Ensure loading is set to false after API call
            }
        };

        fetchData();
    }, [location.pathname, filtersCleared]); 


    const handleFilterChange = async (newFilters , newOrder) => { 

        const filtersToApply = newFilters || currentFilters; 

        if (
            (filtersToApply?.filters?.priceRange?.length === 0 ||
                (filtersToApply?.filters?.priceRange[0] === 0 && filtersToApply?.filters?.priceRange[1] === 100)) &&
            filtersToApply?.filters?.flavors?.length === 0 &&
            filtersToApply?.filters?.sizes?.length === 0 && !newOrder)
        { 
            setFiltersCleared(true); // Set the flag to refetch original items
        }
        else {   
            const isProductListByBrand = location.pathname.includes("ProductListByBrand");
            const isProductListByCat = location.pathname.includes("ProductListByCat");
            const pathSegments = location.pathname.split('/');
            const Name = pathSegments[pathSegments.length - 1].replace(/-/g, ' '); 
             

            const getItems_Filter_order_params = {

                IsItemVisible: true, 
                BrandName: isProductListByBrand ? Name : null,
                CatNameFirstLang: isProductListByCat ? Name : null,
                SubCatNameFirstLang: !isProductListByBrand && !isProductListByCat  ? Name : null,
                FilterParams: {
                    ListItemPrice: filtersToApply?.filters?.priceRange,
                    FlavoresList: filtersToApply?.filters?.flavors,
                    SizesList: filtersToApply?.filters?.sizes
                },
                OrderParams: 
                    newOrder  
            };
            const RespFilter = await GetItems_Filter(getItems_Filter_order_params);
            setItems(RespFilter?.Dt);
        }
    };
    const handleSortIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortChange = (value) => {   
            const newOrder = {}; 
        // Set the appropriate sorting order based on the value
        switch (value) {
            case "price_asc":
                newOrder.price_asc = "price_asc";
                break;
            case "price_desc":
                newOrder.price_desc = "price_desc";
                break;
            case "AtoZ":
                newOrder.AtoZ = "AtoZ";
                break;
            case "ZtoA":
                newOrder.ZtoA = "ZtoA";
                break;
            default:
                // Handle unexpected values if needed
                break;
        } 

        // Call handleFilterChange with current filters and new order
        handleFilterChange(currentFilters, newOrder); 
        setAnchorEl(false); // Close the menu after selection
    };


    if (loading) {
        return (
            <div className={classes.loader}>
                <DumbleGIF isLoading={loading}></DumbleGIF>
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.root}>
                <Helmet>
                    <title>Energize - Product List</title>
                </Helmet>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <IconButton className={classes.filterButton} onClick={toggleFilterDrawer(true)}>
                        <FilterAltOutlinedIcon />Show Filters
                    </IconButton>
                    <FormControl className={classes.formControl} variant="outlined">
                        <IconButton
                            onClick={handleSortIconClick}
                            className={classes.filterButton}>
                            <Sort />Sort
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                            PaperProps={{
                                sx: {
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ padding: '8px 16px' }}>
                                Sort by
                            </Typography>
                            <Divider />
                            <MenuItem onClick={() => handleSortChange("price_asc")} value="price_asc">
                                Price, Low to High
                            </MenuItem>
                            <MenuItem onClick={() => handleSortChange("price_desc")} value="price_desc">
                                Price, High to Low
                            </MenuItem>
                            <MenuItem onClick={() => handleSortChange("AtoZ")} value="AtoZ">
                                Alphabetically, A-Z
                            </MenuItem>
                            <MenuItem onClick={() => handleSortChange("ZtoA")} value="ZtoA">
                                Alphabetically, Z-A
                            </MenuItem>
                        </Menu>
                    </FormControl>
                </Box>
                <Typography variant="h4" component="h1" className="ProductListTitle">
                    {pageTitle}
                </Typography>
                <br />
                {items.length === 0 ? (
                    <Typography variant="h6" align="center" color="textSecondary">
                        There are no items to display.
                    </Typography>
                ) : (
                    <Grid container spacing={1} justifyContent="center">
                        {items.map((product, index) => (
                            <Grid item key={product.ItemId} xs={6} sm={4} md={4} lg={2}>
                                <ProductCardFromList
                                    product={product}
                                    key={index}
                                    brand={product.BrandName}
                                    image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}
                                    name={product.ItemName}
                                    description={product.ItemDescription}
                                    price={product.ItemPrice}
                                    onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                <FilterDrawer
                    open={filterDrawerOpen}
                    onClose={toggleFilterDrawer(false)}
                    onFilterChange={(newFilters) => {
                        setCurrentFilters(newFilters); // Update current filters
                        handleFilterChange(newFilters, null); // Apply new filters
                    }}
                    priceRange={priceRange}
                    selectedFlavors={selectedFlavors}
                    selectedSizes={selectedSizes}
                />
            </Box>
        </ThemeProvider>
    );
}

export default ProductList;

