import React, { useState, useEffect } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import ItemFormDialog from '../components/ItemFormDialog';
import { GetItems, DeleteItems } from '../api/ItemsAPI';
import Backdrop from '@mui/material/Backdrop';
import { blue, red, green, pink } from '@mui/material/colors';
import { Helmet } from 'react-helmet';

const ItemPage = () => {
    const [items, setItems] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        GetItemsAPI();
    }, []);

    const GetItemsAPI = async () => {
        setLoading(true);
        try {
            const response = await GetItems({});   
            setItems(response?.Dt); // Assuming response.data is an array of items
            const errorMsg = response?.Error?.Message;
            if (errorMsg) {
                displaySnackbar('Error loading Data' + errorMsg, 'error');
                return;
            }
        } catch (error) {
            console.error('Error loading items:', error);
            displaySnackbar('Error loading items', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);
    };

    const handleDelete = async (id) => {
        const confirmation = window.confirm("Do you want to Delete?");
        if (confirmation) {
            setLoading(true);
            try {
                const response = await DeleteItems({ ItemId: id });
                const errorMsg = response?.Error?.Message;
                if (errorMsg) {
                    displaySnackbar('Error deleting item: ' + errorMsg, 'error');
                } else {
                    await GetItemsAPI();
                    displaySnackbar('item deleted successfully', 'success');
                }
            } catch (error) {
                console.error('Error deleting item:', error);
                displaySnackbar('Error deleting item', 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleFormSubmit = () => {
        setSelectedItem(null);
        setOpenDialog(false);
        GetItemsAPI();
        displaySnackbar(selectedItem ? 'Item updated' : 'Item added', 'success');
    };

    const handleAdd = () => {
        setSelectedItem(null);
        setOpenDialog(true);
    };

    const columnVisibilityModel = {
        ItemId: false,  
        SubCatId: false,  
        BrandId: false,  
        ItemFileName: false,  
    };

    const columns = [
        { field: 'SubCatId', headerName: 'SubCatId', hide: true },
        { field: 'ItemId', headerName: 'ItemId', hide: true },
        { field: 'BrandId', headerName: 'BrandId', hide: true },
        { field: 'ItemFileName', headerName: 'ItemFileName', hide: true },
        { field: 'SubCatNameFirstLang', headerName: 'Sub-Cat', width: 150 },
        { field: 'ItemName', headerName: 'Item Name', width: 150 },
        //{ field: 'ItemDescription', headerName: 'Item Description', width: 150 },
        { field: 'ItemPrice', headerName: 'Item Price', width: 150 },
        { field: 'ItemOrderNbr', headerName: 'Order Number', width: 150 },
        { field: 'IsItemVisible', headerName: 'Visible', width: 150, type: 'boolean' },   
        { field: 'IsItemEnabled', headerName: 'Enabled', width: 150, type: 'boolean' },   
        { field: 'IsItemIsOnSale', headerName: 'On Sale', width: 150, type: 'boolean' },   
        { field: 'IsItemOnOffre', headerName: 'Offre', width: 150, type: 'boolean' },   
        { field: 'IsItemIsOutOfStock', headerName: 'Out Of Stock', width: 150, type: 'boolean' },   
        { field: 'IsItemIsNewArrival', headerName: 'New Arrival', width: 150, type: 'boolean' },   
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'left', gap: '1px'
                }}>
                    <IconButton onClick={() => handleEdit(params.row)}>
                        <EditIcon sx={{ color: blue[300] }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row.ItemId)}>
                        <DeleteIcon sx={{ color: pink[500] }} />
                    </IconButton>
                </Box>
            ),
        },
    ];

    const getRowId = (row) => row.ItemId;

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ '& > *': { margin: '0.5rem' } }}>
                <Button
                    sx={{ color: 'Green', '&:hover': { bgcolor: green[700], color: 'white' } }}
                    startIcon={<AddIcon />}
                    onClick={handleAdd}
                > Add Item   </Button>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    };

    const displaySnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Helmet>
                <title>Energize - Items</title>
            </Helmet>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ height: 450, width: '100%', mt: 2 }}>
                <DataGrid
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    getRowId={getRowId}
                    rows={items ? items :[]}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 15,
                            },
                        },
                    }}
                    pageSizeOptions={[15]}

                    rowsPerPageOptions={[10, 20, 50]}
                    disableColumnMenu
                    disableRowSelectionOnClick
                />
            </Box>

            <ItemFormDialog
                open={openDialog}
                item={selectedItem}
                onClose={() => setOpenDialog(false)}
                onSubmit={handleFormSubmit}
            />
        </Box>
    );
};

export default ItemPage;
