import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import { InsertFlavor, UpdateFlavor } from '../api/FlavorsAPI'; 

const FlavorDialogForm = ({ open, item, onClose, onSubmit }) => {
    const [error, setError] = useState(null);

    const validationSchema = Yup.object({
        FlavorName: Yup.string().required('Name is required'), 
    });

    const handleClose = () => {
        setError(null); // Clear error state
        onClose();
    };

    const handleNumberChange = (handleChange) => (event) => {
        const { name, value } = event.target;
        // Ensure the value is stored as a number in the form state
        handleChange({ target: { name, value: value === '' ? null : Number(value) } });
    };
     

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" sx={{
            '& .MuiPaper-root': { 
                maxWidth: '400px', // Optional: Set max-width for larger screens
               /* margin: '0 auto',*/
                
            },
        }} >
            <DialogTitle>{item ? 'Edit Flavor' : 'Add New Flavor'}</DialogTitle>
            <Formik
                initialValues={{
                    FlavorId: item ? item.FlavorId : null, 
                    FlavorName: item ? item.FlavorName : null, 
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {  
                        if (item) {  
                            const UpdateRes = await UpdateFlavor(values);
                            const errorMsg = UpdateRes?.Error?.Message;
                            if (errorMsg) { 
                                setError('Error updating Flavor: ' + errorMsg, 'error');
                                return;
                            } 
                        } else {
                            const InsertRes= await InsertFlavor(values);
                            const errorMsg = InsertRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error adding Flavor: ' + errorMsg, 'error');
                                return;
                            } 
                        }
                        onSubmit();
                    } catch (error) {
                        console.error('Failed to submit Cat form', error);
                        setError('Failed to submit Cat form. Please try again.'); // Set error message
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form >
                        <DialogContent >
                            {error && <Alert severity="error">{error}</Alert>} 
                            <TextField
                                required
                                id="txtName"
                                label="Name"
                                name="FlavorName"
                                value={values.FlavorName}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                error={touched.FlavorName && Boolean(errors.FlavorName)}
                                helperText={touched.FlavorName && errors.FlavorName}
                                /> 
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                {item ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FlavorDialogForm;
