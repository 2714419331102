import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Drawer, Fab, Box, Chip } from '@mui/material';
import { AddShoppingCart, CheckCircle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import ProductPD from '../pages/Product_PD';
import { GetItems } from '../api/ItemsAPI';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    badgeContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '5px',
        marginBottom: '10px',
    },
    originalPrice: {
        textDecoration: 'line-through',
        color: 'grey',
        marginRight: '10px',
    },
    card: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        perspective: 1000,
        '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        },
    },
    mediaContainer: {
        //position: 'relative',
    },
    media: {
        transition: 'transform 0.3s',
    },
    content: {
        marginTop: '-65px',
        textAlign: 'center',
    },
    iconButton: {
        color: 'rgb(90, 151, 145)',
        position: 'absolute !importnat',
        bottom: 50,
        left: -5,
        borderRadius: '50%',
        animation: '$iconAnimation 1s ease',
    },
    '@keyframes iconAnimation': {
        '0%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(1.5)' },
        '100%': { transform: 'scale(1)' },
    },
    success: {
        color: 'green',
        animation: '$successAnimation 1s ease',
    },
    '@keyframes successAnimation': {
        '0%': { transform: 'scale(0)' },
        '100%': { transform: 'scale(1)' },
    },
    drawerContent: {
        width: '100%',
        height: '80px',
    },
});

const ProductCardFromList = ({ product, image, name, description, price, brand, onCardClick }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [added, setAdded] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleAddClick = () => {
        setAdded(true);
        setTimeout(() => setDrawerOpen(true), 1000); // Reset after 2 seconds
        setTimeout(() => setAdded(false), 2000); // Reset after 2 seconds
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    }; 

    const brandNameClick = async (event, BrandName, Item) => {
        event.stopPropagation(); 
        navigate(`/ProductListByBrand/${BrandName.replace(/\s/g, '-')}`)
    };



    return (
        <>
            <Card className={classes.card} onClick={onCardClick}>
                <div className={classes.mediaContainer}>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={image}
                        title={name}
                    />
                    <IconButton
                        className={`${classes.iconButton} ${added ? classes.success : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAddClick();
                        }}
                        disabled={product.IsItemIsOutOfStock}
                    >
                        {added ? (
                            <Fab size="small" className="AddedCheckCircleCardBtn">
                                <CheckCircle />
                            </Fab>
                        ) : (
                            <Fab size="small" className="AddCardBtn">
                                <AddShoppingCart />
                            </Fab>
                        )}
                    </IconButton>
                </div>
                <CardContent className={classes.content}>
                    <div className={classes.badgeContainer}>
                        {product.IsItemIsOnSale && (
                            <Chip label="Sale" color="primary" size="small" />
                        )}
                        {product.IsItemIsNewArrival && (
                            <Chip label="New Arrival" color="secondary" size="small" />
                        )}
                        {product.IsItemIsOutOfStock && (
                            <Chip label="Out of Stock" color="error" size="small" />
                        )}
                    </div> 
                    <Typography variant="body2" color="textSecondary"
                        onClick={(event) => {
                            event.stopPropagation();
                            brandNameClick(event, product?.BrandName, product);
                        }}
                    >
                        <u>{product?.BrandName}</u>
                    </Typography> 
                    <Typography className="ItemNameNewArrivalCSS">
                        {name}
                    </Typography>

                    {product.IsItemIsOnSale ? (
                        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                            <Typography variant="subtitle2">
                                <b>{price}</b>
                            </Typography>
                            <Typography variant="body2" className={classes.originalPrice}>
                                {product.ItemPriceBeforeSale}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography className="ItemNameNewArrivalCSS">
                            <b>{price}</b>
                        </Typography>
                    )}
                </CardContent>
            </Card>

            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div className={classes.drawerContent} role="presentation">
                    <ProductPD ProductToCart={product} onclosefct={closeDrawer} />
                </div>
            </Drawer>
        </>
    );
};

export default ProductCardFromList;
