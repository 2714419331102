import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, IconButton, Link, Box, keyframes } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EnergizeLogo from '../collection/EnergizeLogo.jpg';
import { GetLinks } from '../api/LinksAPI';

// Define the keyframes for the "boomb" effect
const boombAnimation = keyframes` 
  70% { transform: scale(0.9); }
  100% { transform: scale(1); opacity: 1; }
`;

const Footer = () => {
    const [links, setLinks] = useState({
        FbLink: '',
        InstaLink: '',
        TwitterLink: '',
        WhatsapNumber: '',
        LocationLink: '',
        PhoneNumber: '',
        EmailAddress: '',
    });

    // Separate useInView hooks for different sections without triggerOnce
    const { ref: logoRef, inView: logoInView } = useInView({
        threshold: 0.1,
    });
    const { ref: storeRef, inView: storeInView } = useInView({
        threshold: 0.1,
    });
    const { ref: contactRef, inView: contactInView } = useInView({
        threshold: 0.1,
    });
    const { ref: socialRef, inView: socialInView } = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetLinks({});
                const linkData = response.Dt.reduce((acc, link) => {
                    acc[link.LinkName] = link.LinkValue;
                    return acc;
                }, {});

                setLinks(linkData);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);


    return (
        <Box
            id="footer"
            component="footer"
            sx={{
                backgroundColor: 'rgb(44,44,44)',
                color: 'white',
                py: 6,
                textAlign: 'center',
                mt: 4,
                position: 'relative',
            }}
        >
            <Container>
                <Box
                    sx={{
                        mb: 3,
                        animation: logoInView ? `${boombAnimation} 0.8s ease-out` : 'none',
                        display: 'inline-block'
                    }}
                    ref={logoRef}
                >
                    <img src={EnergizeLogo} alt="Logo" style={{ width: '150px', height: 'auto' }} />
                </Box>
                <Grid container spacing={4} justifyContent="center">
                    {/* Store Information */}
                    <Grid item xs={12} sm={4}>
                        <Typography
                            gutterBottom
                            sx={{
                                textTransform: 'uppercase',
                                letterSpacing: '0.2em',
                                fontWeight: 'bold',
                                color: 'gray',
                                mb: 2,
                                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                pb: 1,
                                position: 'relative',
                                '&:after': {
                                    content: '""',
                                    display: 'block',
                                    width: '80%',
                                    height: '3px',
                                    backgroundColor: 'gray',
                                    margin: '0 auto',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }
                            }}
                        >
                            Visit Our Store
                        </Typography>
                        <Box
                            ref={storeRef}
                            sx={{
                                transform: 'translateX(-100px)',
                                opacity: 0,
                                transition: 'all 0.8s ease',
                                ...(storeInView && {
                                    transform: 'translateX(0)',
                                    opacity: 1,
                                }),
                            }}
                        >
                            <IconButton
                                color="inherit"
                                component={Link}
                                href={links.LocationLink}
                                target="_blank"
                                sx={{ color: 'rgb(90,151,145)' }}
                            >
                                <LocationOnIcon sx={{ fontSize: 30 }} />
                                <Typography variant="body2" sx={{ ml: 1, fontWeight: 'bold' }}>
                                    {links.RegionName}
                                </Typography>
                            </IconButton>
                        </Box>
                    </Grid>

                    {/* Get in Touch */}
                    <Grid item xs={12} sm={4}>
                        <Typography
                            gutterBottom
                            sx={{
                                textTransform: 'uppercase',
                                letterSpacing: '0.2em',
                                fontWeight: 'bold',
                                color: 'gray',
                                mb: 2,
                                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                pb: 1,
                                position: 'relative',
                                '&:after': {
                                    content: '""',
                                    display: 'block',
                                    width: '80%',
                                    height: '3px',
                                    backgroundColor: 'gray',
                                    margin: '0 auto',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }
                            }}
                        >
                            Get in Touch
                        </Typography>
                        <Box
                            ref={contactRef}
                            sx={{
                                transform: 'translateX(-100px)',
                                opacity: 0,
                                transition: 'all 0.8s ease',
                                ...(contactInView && {
                                    transform: 'translateX(0)',
                                    opacity: 1,
                                }),
                            }}
                        >
                            <IconButton
                                color="inherit"
                                component={Link}
                                href={`tel:${links.PhoneNumber}`}
                                sx={{ color: 'rgb(90,151,145)' }}
                            >
                                <PhoneIcon sx={{ fontSize: 30 }} />
                                <Typography variant="body2" sx={{ ml: 1, fontWeight: 'bold' }}>
                                    Call Us: {links.PhoneNumber}
                                </Typography>
                            </IconButton>
                            <IconButton
                                color="inherit"
                                component={Link}
                                href={`mailto:${links.EmailAddress}`}
                                sx={{ color: 'rgb(90,151,145)' }}
                            >
                                <EmailIcon sx={{ fontSize: 30 }} />
                                <Typography variant="body2" sx={{ ml: 1, fontWeight: 'bold' }}>
                                    Contact Us: {links.EmailAddress}
                                </Typography>
                            </IconButton>
                        </Box>
                    </Grid>

                    {/* Social Media */}
                    <Grid item xs={12} sm={4}>
                        <Typography
                            gutterBottom
                            sx={{
                                textTransform: 'uppercase',
                                letterSpacing: '0.2em',
                                fontWeight: 'bold',
                                color: 'gray',
                                mb: 2,
                                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                pb: 1,
                                position: 'relative',
                                '&:after': {
                                    content: '""',
                                    display: 'block',
                                    width: '80%',
                                    height: '3px',
                                    backgroundColor: 'gray',
                                    margin: '0 auto',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }
                            }}
                        >
                            Connect with Us
                        </Typography>
                        <Box
                            ref={socialRef}
                            sx={{
                                transform: 'translateX(-100px)',
                                opacity: 0,
                                transition: 'all 0.8s ease',
                                ...(socialInView && {
                                    transform: 'translateX(0)',
                                    opacity: 1,
                                }),
                            }}
                        >
                            <IconButton
                                color="inherit"
                                component={Link}
                                href={links.FbLink}
                                target="_blank"
                                sx={{ color: 'rgb(90,151,145)' }}
                            >
                                <FacebookIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                component={Link}
                                href={links.InstaLink}
                                target="_blank"
                                sx={{ color: 'rgb(90,151,145)' }}
                            >
                                <InstagramIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                component={Link}
                                href={`https://wa.me/${links.WhatsapNumber}`}
                                target="_blank"
                                sx={{ color: 'rgb(90,151,145)' }}
                            >
                                <WhatsAppIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                {/* Copyright Notice */}
                <Box sx={{ mt: 4 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: 'rgb(90,151,145)',
                            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                            transform: 'perspective(500px) rotateX(20deg)',
                            transition: 'transform 0.5s ease-in-out',
                            '&:hover': {
                                transform: 'perspective(500px) rotateX(0deg)',
                                color: 'rgb(255,215,0)',
                                textShadow: '2px 2px 8px rgba(0,0,0,0.5)',
                            },
                        }}
                    >
                        &copy; {new Date().getFullYear()} Energize lb. All Rights Reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
