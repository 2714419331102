import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

// Custom image handler for Quill editor

Quill.register('modules/imageResize', ImageResize);
function imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
        const file = input.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const range = this.quill.getSelection();
                this.quill.insertEmbed(range.index, 'image', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
}

const modules = {
    toolbar: {
        container: [
            [{ 'font': [] }, { 'size': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'], // Add the image button here
            ['clean']
        ],
        handlers: {
            image: imageHandler // Use the custom image handler
        },
        imageResize: {
            // Image resize options
            modules: ['Resize', 'DisplaySize']
        }
    }
};

const formats = [
    'font', 'size', 'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'color', 'background', 'align',
    'script'
];

function RichTextbox({ title, value, onChange }) {
    return (
        <div>
            <div>{title}</div>
            <ReactQuill
                placeholder={`Put your ${title} here`}
                theme="snow"
                value={value}
                onChange={onChange}
                modules={modules}
                formats={formats}
            />
        </div>
    );
}

export default RichTextbox;
