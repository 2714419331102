import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Avatar, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff, Lock as LockIcon } from '@mui/icons-material'; // Import LockIcon as an alias
import { EncryptString } from '../components/GeneralFunctions';
import { loginAPI } from '../api/authentication';
import { useNavigate } from 'react-router-dom';   
import { Helmet } from 'react-helmet';

function LogIn({ setIsAuthorized, handleClick }) {
    const navigate = useNavigate(); // Use useHistory hook to get history object


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // State to manage loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 
        try {
            auth();  
        } catch (error) {
            console.error('Login Failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const auth = async () => {
        if (username && password) {
            setLoading(true);
            const result = await loginAPI({
                UserName: await EncryptString(username),
                Password: await EncryptString(password)
            })
            if (result.JWT) {
                setIsAuthorized(true);
                handleClick(); 
                navigate('/CMS/Dashboard');
            }
            else {
                setLoading(false);
                alert("Please Check username and password or contact admin")
            }
        }
        else {
            alert("Please fill username and password ")
        }
    }

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const avatarStyle = {
        backgroundColor: '#007bff', // Blue background for the avatar
        margin: 'auto', // Center the avatar horizontally
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'Top', paddingTop: '80px' }}>
            <Helmet>
                <title>Energize - Login</title>
            </Helmet>
            <form onSubmit={handleSubmit} style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', width: '300px' }}>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Avatar style={avatarStyle}>
                        <LockIcon />
                    </Avatar>
                    <h2>Login</h2>
                </div>
                <TextField
                    id="username"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ marginBottom: '15px' }}
                />
                <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handlePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    style={{ marginBottom: '15px' }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading} // Disable button when loading
                    style={{ marginBottom: '15px', position: 'relative' }}
                >
                    {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />} {/* Show CircularProgress if loading */}
                    {!loading ? 'Login' : 'Logging In...'}
                </Button>
            </form>
        </div>
    );
};

export default LogIn;
