import React, { useState, useEffect } from "react";
import { styled } from '@mui/system'; 
import ToolbarComponent from "./ToolBar";
import DrawerComponent from "./Drawer";
 


export function Appbar() {
    const [Cat_SubCat_Item_COUNT, setCat_SubCat_Item_COUNT] = useState([]) 
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [RestnameResponse, setRestnameResponse] = useState([]);

    const toggleDrawer = () => {
        setDrawerOpen(false);
    };
    const openDrawer = () => {
        setDrawerOpen(true);
    }; 
    useEffect(() => {  
    }, [isDrawerOpen]);  

    const FctOpenSearch = () => { 
        setOpenSearch(true);
    }; 
    return ( 
        <div className="App">
            <ToolbarComponent openDrawerHandler={openDrawer} stateopenSearch={openSearch} setstateopenSearch={setOpenSearch} />
            <DrawerComponent open={isDrawerOpen} toggleDrawerHandler={toggleDrawer} OpenSearchDialogue={FctOpenSearch} />
        </div> 
    );
}

export default Appbar;
