const AppConfig = { 

    apiUrl: 'https://energizelb.originsbackend.online/api',
    ImageUrl: 'https://energizelb.originsbackend.online/Images',

    //apiUrl: 'https://localhost:7112/api',
    //ImageUrl: 'https://localhost:7112/Images',

    KeyEncryptoJS:'%2^Y*j^7(v7$G?8=',
    IVEncryptoJS: 'j%262$@4h&j#y(0q',


    PhoneNumberSendLinksWhats:'96170656379'
  };
  
  export default AppConfig; 