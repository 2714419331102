import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { IconButton, Collapse, Typography, Box, Fade } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Divider from '@mui/material/Divider';

const HtmlContent = ({ title, htmlString, defaultCollapsed = true }) => {
    const [open, setOpen] = useState(!defaultCollapsed);

    // Sanitize the HTML string
    const sanitizedHtml = DOMPurify.sanitize(htmlString);

    const handleToggle = () => {
        setOpen(!open);
    };

    // Check if htmlString is not empty or just whitespace
    const hasContent = htmlString && htmlString.trim() !== '' && htmlString !== "<p><br></p>";

    return (
        hasContent && (
            <Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleToggle}
                >
                    <b><Typography variant="h6"> {title}</Typography></b>

                    <IconButton
                        onClick={handleToggle}
                        sx={{ color: 'rgb(90,151,145)' }}
                    >
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={open}>
                    <Fade in={open} timeout={500}>
                        <div
                            className="view ql-editor"
                            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                        />
                    </Fade>
                    <br />
                </Collapse>
                <Divider/>
            </Box>
        )
    );
};

export default HtmlContent;
