import React, { useState, useEffect } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import CategorieFormDialog from '../components/CategorieFormDialog';
import { GetCategorys, DeleteCategory } from '../api/CategoriesApis';
import Backdrop from '@mui/material/Backdrop';
import { blue, red, green, pink } from '@mui/material/colors';
import { Helmet } from 'react-helmet';

const CategoriesPage = () => {
    const [items, setItems] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        setLoading(true);
        try {
            const response = await GetCategorys({});
            setItems(response?.Dt); // Assuming response.data is an array of items

            const errorMsg = response?.Error?.Message;
            if (errorMsg) {
                displaySnackbar('Error loading Data' + errorMsg, 'error');
                return;
            }

        } catch (error) {
            console.error('Error loading items:', error);
            displaySnackbar('Error loading items', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);
    };

    const handleDelete = async (id) => {
        const confirmation = window.confirm("Do you want to Delete?");
        if (confirmation) {
            setLoading(true);
            try {
                const response = await DeleteCategory({ CatId: id });
                const errorMsg = response?.Error?.Message;
                if (errorMsg) {
                    displaySnackbar('Error deleting category: ' + errorMsg, 'error');
                } else {
                    await getCategories();
                    displaySnackbar('Category deleted successfully', 'success');
                }
            } catch (error) {
                console.error('Error deleting category:', error);
                displaySnackbar('Error deleting category', 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleFormSubmit = () => {
        setSelectedItem(null);
        setOpenDialog(false);
        getCategories();
        displaySnackbar(selectedItem ? 'Category updated' : 'Category added', 'success');
    };

    const handleAdd = () => {
        setSelectedItem(null);
        setOpenDialog(true);
    };

    const columnVisibilityModel = {
        CatId: false, // Hide the "Cat Name" column
    };

    const columns = [
        { field: 'CatId', headerName: 'CatId', hide: true },
        { field: 'CatNameFirstLang', headerName: 'Category Name', width: 150 },
        { field: 'OrderNbr', headerName: 'Order Number', width: 150 },
        { field: 'IsCatVisible', headerName: 'Visible', width: 150, type: 'boolean' }, 
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'left',  gap: '1px' }}>
                    <IconButton onClick={() => handleEdit(params.row)}>
                        <EditIcon sx={{ color: blue[300] }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row.CatId)}>
                        <DeleteIcon sx={{ color: pink[500] }} />
                    </IconButton>
                </Box>
            ),
        },
    ];

    const getRowId = (row) => row.CatId;

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ '& > *': { margin: '0.5rem' } }}>
                <Button
                    sx={{  color: 'Green', '&:hover': { bgcolor: green[700] ,color:'white'} }}
                    startIcon={<AddIcon />}
                    onClick={handleAdd}
                >
                    Add Cat
                </Button>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    };

    const displaySnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Helmet>
                <title>Energize - Categories</title>
            </Helmet>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ height: 450, width: '100%', mt: 2 }}>
                <DataGrid
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    getRowId={getRowId}
                    rows={items ? items : []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 15,
                            },
                        },
                    }}
                    pageSizeOptions={[15]}

                    rowsPerPageOptions={[10, 20, 50]}
                    disableColumnMenu
                    disableRowSelectionOnClick 
                />
            </Box>

            <CategorieFormDialog
                open={openDialog}
                item={selectedItem}
                onClose={() => setOpenDialog(false)}
                onSubmit={handleFormSubmit}
            />
             
        </Box>
    );
};

export default CategoriesPage;
