import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GetCat_SubCatObject } from '../api/SubCategoriesAPI';
import { useNavigate } from 'react-router-dom';

export default function DrawerComponent(props) {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isRotated, setIsRotated] = useState(false);
    const [listItemsToDisplay, setListItemsToDisplay] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState(['Menu']);
    const [catSubCatObject, setCatSubCatObject] = useState([]);
    const [originalResponse, setOriginalResponse] = useState([]);
    const navigate = useNavigate();

    const toggleRotation = () => {
        setIsRotated(!isRotated);
    };

    const fetchCategories = async () => {
        try {
            const response = await GetCat_SubCatObject({});
            setListItemsToDisplay(response);
            setOriginalResponse(response);
        } catch (error) {
            console.error('Failed to fetch categories', error);
        }
    };

    const handleListItemClick = async (event, index, item) => {
        if (item?.SubCatId) {
            props.toggleDrawerHandler();
            navigate(`/ProductList/${item?.SubCatNameFirstLang.replace(/\s/g, '-')}`);
        } else if (item.name === "Shop") {
            setBreadcrumb(['Shop']);
            await fetchCategories();
        } else if (item.name === "Home") {

            props.toggleDrawerHandler();
            // Handle Home navigation
            navigate('/');
        } else if (item.name === "About Us") {

            props.toggleDrawerHandler();
            navigate('/about-us');
        }
        else if (item.name === "Search") {
            props.OpenSearchDialogue();
            props.toggleDrawerHandler(); 
        } else {
            const newBreadcrumb = [...breadcrumb, item.CatNameFirstLang];
            setBreadcrumb(newBreadcrumb);
            setSelectedIndex(index);
            setListItemsToDisplay(item?.SubCategoriesobj);
        }
        event.preventDefault();
    };

    const handleBack = (event) => { 
        if (breadcrumb.length > 1) {
            const newBreadcrumb = breadcrumb.slice(0, -1);
            setBreadcrumb(newBreadcrumb);
            setListItemsToDisplay(originalResponse);
        } else {
            setBreadcrumb(['Menu']);
            setListItemsToDisplay(initialListItems);
        }
        event.stopPropagation();
    };

    const initialListItems = [
        { name: 'Home' },
        { name: 'Shop' },
        { name: 'About Us' }, 
        { name: 'Search' },
    ];

    useEffect(() => {
        if (props.open) {
            setBreadcrumb(['Menu']);
            setListItemsToDisplay(initialListItems);
        }
    }, [props.open]);

    return (
        <Drawer
            open={props.open}
            onClose={props.toggleDrawerHandler}
            className="list-item-transition"
        >
            <Box
                sx={{
                    width: '100vw',
                    maxWidth: 550,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: 'rgb(44,44,44)',
                    color: 'white',
                    fontFamily: 'Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif',
                }}
                role="presentation"
            >
                <br />
                <Button
                    onClick={props.toggleDrawerHandler}
                    onMouseEnter={toggleRotation}
                    onMouseLeave={toggleRotation}
                    sx={{
                        marginLeft: '-10px',
                        alignSelf: 'flex-start',
                        transition: 'transform 0.3s ease',
                        transform: isRotated ? 'rotate(90deg)' : 'rotate(0deg)',
                    }}
                >
                    <CloseIcon sx={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: 20 }} />
                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }} className="list-item-transition">
                    <Button
                        onClick={(event) => handleBack(event)}
                        sx={{
                            color: 'white',
                            minWidth: 'auto',
                            marginRight: '5px',
                        }}
                    >
                        {breadcrumb.length === 1 && breadcrumb[0] === 'Menu' ? null : (
                            <ArrowBackIcon sx={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: 18 }} />
                        )}

                    </Button>

                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>
                            {breadcrumb[breadcrumb.length - 1]}
                        </Typography>
                    </Box>
                </Box>

                <List>
                    {listItemsToDisplay?.map((item, index) => (
                        <ListItem
                            key={item?.CatId || item?.SubCatId || item.name}
                            disablePadding
                            onClick={(event) => handleListItemClick(event, index, item)}
                            className="list-item-transition"
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    transition: 'background-color 0.3s ease',
                                },
                                backgroundColor: 'transparent',
                            }}
                        >
                            <ListItemButton>
                                <ListItemText primaryTypographyProps={{ style: { fontSize: 16 } }} primary={item?.DisplayNameInDrawer || item.name} />
                                {(item?.CatId || item.name ==='Shop' ) && (
                                    <ArrowForwardIcon sx={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: 18 }} />
                                )}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
            </Box>
        </Drawer>
    );
}
