import React from 'react';
import EnergizeLogo from '../collection/EnergizeLogo.jpg';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const Mylogo = () => {

    const navigate = useNavigate();
    return (
        <Typography 
            noWrap 
            sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center', // Center aligns vertically
                justifyContent: 'center', // Center aligns horizontally
            }}
        >
            <img 
                onClick={(event) => navigate('/')} 
                src={EnergizeLogo}
                alt="Logo"
                style={{
                    width: 'auto',
                    height: 'auto',
                    maxHeight: '20vh', // Adjust as needed
                    maxWidth: '80%', // Adjust as needed
                }}
            />
        </Typography>
    );
};

export default Mylogo;
