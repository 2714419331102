import axios from 'axios'
import AppConfig from '../AppSettings';

export const GetCat_SubCatObject = async (data) => { 
    try {

        // Create a unique cache key based on the request parameters
        const cacheKey = `GetCat_SubCatObject_${JSON.stringify(data)}`;

        // Check if the data is already in the cache
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
            // Return cached data if available
            return JSON.parse(cachedData);
        }


        const response = await fetch(AppConfig.apiUrl + `/Sub_Categories/GetCat_SubCatObject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();


        // Store the result in the sessionStorage cache
        sessionStorage.setItem(cacheKey, JSON.stringify(result));

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}
export const GetSubCategorys = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Sub_Categories/GetSubCat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}



export const InsertSubCategory = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Sub_Categories/InsertSubCat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const UpdateSubCategorys = async (data) => {
    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Sub_Categories/UpdateSubCat`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

export const DeleteSubCategory = async (data) => {

    const token = localStorage.getItem("jwt");
    try {
        const response = await fetch(AppConfig.apiUrl + `/Sub_Categories/DeleteSubCat`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        const result = {
            Error: {
                Message: error
            }
        }
        return result;
    }
}

