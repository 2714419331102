import { useTheme, styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { GetComments, DeleteComments } from '../api/GetInTouchAPI';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from 'react-helmet';
import { blue, red, green, pink } from '@mui/material/colors';

const restructureData = (data) => {
    return data.map((structure) => {
        structure.delete = structure.CommentId;
        return structure;
    });
};

const StyledGridToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
    '& > *': {
        margin: theme.spacing(0.5),
    },
}));

const CustomToolbar = ({ selectedCategories, removeBulkComments }) => (
    <StyledGridToolbarContainer>
        <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => removeBulkComments(selectedCategories)}
            disabled={selectedCategories.length === 0}
        >
            Delete Selected
        </Button>
        <GridToolbarFilterButton />
        <GridToolbarExport />
    </StyledGridToolbarContainer>
);

export default function BasicTable() {
    const [data, setData] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        getCommentsAPI();
    }, []);

    const getCommentsAPI = async () => {
        const result = await GetComments({ RestId: localStorage.getItem("RestaurantId") });
        setData(restructureData(result?.Dt));
    };

    const removecomment = async (id) => {
        const confirmation = window.confirm("Do you want to Delete?");
        if (confirmation) {
            await DeleteComments({ CommentId: id });
            getCommentsAPI();
        }
    };

    const callRemoveCommentFromBulk = async (id) => {
        await DeleteComments({ CommentId: id });
        getCommentsAPI();
    };

    const removeBulkComments = (ids) => {
        const confirmation = window.confirm("Do you want to Delete the selected comments?");
        if (confirmation) {
            ids.forEach((id) => {
                callRemoveCommentFromBulk(id);
            });
        }
    };

    const columns = [
        { field: 'CommentId', headerName: 'ID', width: 70, hide: true },
        { field: 'name', headerName: 'name', width: 120 },
        { field: 'message', headerName: 'message', width: 300 },
        { field: 'email', headerName: 'email', width: 250 },
        { field: 'CreationDateTime', headerName: 'created_at', width: 250 },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false, 
            renderCell: (params) => (
                <Button
                    size="large"  
                    startIcon={<DeleteIcon sx={{ color: pink[500] }} />}
                    onClick={() => removecomment(params.value)}
                />
            ),
        },
    ];

    const getRowId = (row) => row.CommentId;
    const columnVisibilityModel = {
        CommentId: false, 
    };
    return (
        <div style={{ height: 500, width: '100%' }}>
            <Helmet>
                <title>Energize -Get In Touch</title>
            </Helmet>

            <DataGrid
                columnVisibilityModel={columnVisibilityModel}
                getRowId={getRowId}
                rows={data}
                columns={columns}
                pageSize={120} 
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectedCategories(newSelectionModel);
                }}  
            />
        </div>
    );
}
