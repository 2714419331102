import React, { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Container, Button, Menu, MenuItem, Typography, Fade } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MyLogo from '../components/Mylogo';
import SearchPage from './SearchPage';
import ViewCart from './ViewCart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GetCat_SubCatObject } from '../api/SubCategoriesAPI';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const ToolbarComponent = (props) => {
    const { cart } = useCart();
    const theme = createTheme();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [openDialog, setOpenDialog] = useState(props.stateopenSearch);
    const [openDialogViewCart, setopenDialogViewCart] = useState(false);
    const [listItemsToDisplay, setListItemsToDisplay] = useState([]);
    const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
    const [currentSubCategories, setCurrentSubCategories] = useState([]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setAnchorElSubMenu(null);
    };

    const handleOpenSubMenu = (event, subCategories) => {
        setAnchorElSubMenu(event.currentTarget);
        setCurrentSubCategories(subCategories);
    };

    const handleCloseSubMenu = () => {
        setAnchorElSubMenu(null);
    };

    const handleSearchBtn = () => {
        setOpenDialog(true);
    };

    const handleViewCartBtn = () => {
        setopenDialogViewCart(true);
    };

    useEffect(() => {
        setOpenDialog(props.stateopenSearch);
    }, [props.stateopenSearch]);

    const closeSearchDialog = () => {
        setOpenDialog(false);
        props.setstateopenSearch(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const Cat_SubCat_Object = await GetCat_SubCatObject({});
                setListItemsToDisplay(Cat_SubCat_Object);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [props.open]);

    const handleListItemClick = async (event, index, Item) => {
        if (Item?.SubCatId) {
            navigate(`/ProductList/${Item?.SubCatNameFirstLang.replace(/\s/g, '-')}`);
            handleCloseNavMenu();
        }
    };

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    return (
        <AppBar position="static" sx={{ backgroundColor: 'rgb(44,44,44)' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            sx={{ my: 2, mx: 3, color: 'rgb(90,151,145)', display: 'block' }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{ my: 2, mx: 3, color: 'rgb(90,151,145)', display: 'block' }}
                            onClick={() => navigate('/about-us')}
                        >
                            About Us
                        </Button>
                        <Box sx={{ position: 'relative', mx: 3 }}>
                            <Button
                                onMouseEnter={(e) => handleOpenSubMenu(e, listItemsToDisplay)}
                                sx={{ my: 2, color: 'rgb(90,151,145)', display: 'block' }}
                            >
                                Shop
                            </Button>
                            <Menu
                                MenuListProps={{ onMouseLeave: handleCloseSubMenu }}
                                anchorEl={anchorElSubMenu}
                                open={Boolean(anchorElSubMenu)}
                                onClose={handleCloseSubMenu} 
                                sx={{ width: 'auto', minWidth: '300px' ,}} // Ensure Menu has appropriate width
                            >
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns
                                        gap: '16px', // Space between items
                                        padding: '16px',
                                        backgroundColor: 'rgb(44,44,44)',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                        border: '1px solid rgba(255,255,255,0.1)',
                                    }}
                                >
                                    {listItemsToDisplay.map((category) => (
                                        <Box
                                            key={category.CatId}
                                            sx={{
                                                transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                                                transform: Boolean(anchorElSubMenu) ? 'scale(1)' : 'scale(0.95)',
                                                opacity: Boolean(anchorElSubMenu) ? 1 : 0,
                                                backgroundColor: 'rgb(44,44,44)', 
                                                padding: '15px',
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                                border: '1px solid rgba(255,255,255,0.1)',
                                                '&:hover': {
                                                    backgroundColor: 'rgb(60,60,60)',
                                                },
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ padding: '8px 16px', fontWeight: 'bold', color: 'rgb(90,151,145)' }}>
                                                {category.DisplayNameInDrawer}
                                            </Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '16px' }}>
                                                {category.SubCategoriesobj.map((Item, index) => (
                                                    <MenuItem
                                                        key={Item.SubCatId}
                                                        onClick={(event) => handleListItemClick(event, index, Item)}
                                                        sx={{
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: 'rgb(60,60,60)',
                                                                borderRadius: '5px',
                                                                textDecoration: 'underline',  // Add this line to underline text on hover
                                                                
                                                            }
                                                        }}
                                                    >
                                                        {Item.DisplayNameInDrawer}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Menu>
                        </Box>


                    </Box>

                    {/* Mobile Menu */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <IconButton
                            sx={{ marginLeft: '-10px' }}
                            onClick={props.openDrawerHandler}
                            color="inherit"
                        >
                            <MenuIcon sx={{ fontSize: '28px', color: 'rgb(90,151,145)' }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar1"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {listItemsToDisplay.map((category) => (
                                <MenuItem key={category.CatId} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{category.DisplayNameInDrawer}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <MyLogo />

                    <IconButton
                        sx={{ marginLeft: '-10px' }}
                        color="inherit"
                        onClick={handleSearchBtn}
                    >
                        <SearchIcon sx={{ fontSize: '28px', color: 'rgb(90,151,145)' }} />
                    </IconButton>

                    <ThemeProvider theme={theme}>
                        <IconButton aria-label="cart" onClick={handleViewCartBtn} color="inherit">
                            <StyledBadge badgeContent={cart?.length} color="secondary">
                                <ShoppingCartOutlinedIcon sx={{ fontSize: '28px', color: 'rgb(90,151,145)' }} />
                            </StyledBadge>
                        </IconButton>
                        <ViewCart open={openDialogViewCart} onClose={() => setopenDialogViewCart(false)} />
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <SearchPage open={openDialog} onClose={closeSearchDialog} />
                    </ThemeProvider>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ToolbarComponent;
