import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import { InsertItems, UpdateItems } from '../api/ItemsAPI';
import { GetSubCategorys } from '../api/SubCategoriesAPI';
import { GetPackageDetails } from '../api/PackageDetailsAPI';
import { uploadImage, DeleteFileUpload } from '../api/HelperAPI';
import { GetBrand } from '../api/BrandsAPI';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { DataGrid } from '@mui/x-data-grid';
import PackageDetailDialog from './PackageDetailsFormDialog'
import { blue, red, green, pink } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AppConfig from '../AppSettings'; 
import Grid from '@mui/material/Grid';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'; 
import RichTextbox from './RichTextbox';
import {  
    Autocomplete,  
    ListItemText, 
} from '@mui/material';

const ItemFormDialog = ({ open, item, onClose, onSubmit }) => { 
    const [error, setError] = useState(null);
    const [subCats, setSubCats] = useState([]);
    const [brands, setBrands] = useState([]);
    const [PackageDetails, setPackageDetails] = useState([]);
    const [isPackageDetailDialogOpen, setIsPackageDetailDialogOpen] = useState(false);
    const [editingPackageDetail, setEditingPackageDetail] = useState(null);

    const [ItemFileImage, setItemFileImage] = useState(null);
    const [ItemFileNameURL, setItemFileNameURL] = useState('');
    const [isImageRemoved, setIsImageRemoved] = useState(false);
    const [originalImageUrl, setOriginalImageUrl] = useState('');


    useEffect(() => { 
        //reset 
        setItemFileNameURL('');
        setOriginalImageUrl('');
        setIsImageRemoved(false);


        if (open) {
            const fetchData = async () => {
                try {
                    const catsResp = await GetSubCategorys({});
                    setSubCats(catsResp?.Dt || []);  

                    const brandsResp = await GetBrand({});
                    setBrands(brandsResp?.Dt || []);


                    if (item) {
                        const paramsSelect = { ItemId: item?.ItemId };
                        const backdetResp = await GetPackageDetails(paramsSelect);
                        setPackageDetails(backdetResp?.Dt);

                        if (item?.ItemFileName) {
                            setOriginalImageUrl(`${AppConfig.ImageUrl}/${item?.ItemFileName}`)
                            setItemFileNameURL(`${AppConfig.ImageUrl}/${item?.ItemFileName}`)
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch data', error);
                }
            };

            fetchData();
        } else {
            setSubCats([]);
            setBrands([]);
            setPackageDetails([]);
        }
    }, [open, item]);

    const validationSchema = Yup.object({
        ItemName: Yup.string().required('Name is required'),
        SubCatId: Yup.number().required('Sub-Category is required'),
    });

    const handleClose = () => {

        setError(null);
        onClose();
    };

    const handleNumberChange = (handleChange) => (event) => {
        const { name, value } = event.target;
        handleChange({ target: { name, value: value === '' ? null : Number(value) } });
    };

    const handleAddPackageDetail = (newPackageDetail) => { 
        if (editingPackageDetail) {
            // Edit existing package detail
            setPackageDetails((prevDetails) =>
                prevDetails.map((detail) =>
                    detail.PackageDetailID === editingPackageDetail.PackageDetailID
                        ? { ...newPackageDetail, PackageDetailID: detail.PackageDetailID }
                        : detail
                )
            );
        } else {
            // Add new package detail
            setPackageDetails((prevDetails) => [
                ...prevDetails,
                { ...newPackageDetail, PackageDetailID: Date.now() } // Use Date.now() to create a unique ID
            ]);
        }

        //// Override images object if editingPackageDetail is present
        //if (editingPackageDetail) { 
        //    // Assuming images object is part of newPackageDetail
        //    // You can override like this:
        //    setPackageDetails((prevDetails) =>
        //        prevDetails.map((detail) =>
        //            detail.PackageDetailID === editingPackageDetail.PackageDetailID
        //                ? { ...detail, AllImagesContainedImagesFromDialog: newPackageDetail.Images }
        //                : detail
        //        )
        //    );
        //}

        setIsPackageDetailDialogOpen(false);
        setEditingPackageDetail(null);
    };


    const handleEditPackageDetail = (id) => {
        const detailToEdit = PackageDetails.find((detail) => detail.PackageDetailID === id);
        setEditingPackageDetail(detailToEdit);
        setIsPackageDetailDialogOpen(true);
    };

    const handleDeletePackageDetail = (id) => {
        const confirmation = window.confirm("Do you want to Delete?");
        if (confirmation) {
            setPackageDetails((prevDetails) => prevDetails.filter((detail) => detail.PackageDetailID !== id));
            setEditingPackageDetail(null);
        }
    };

    const getRowId = (row) => row.PackageDetailID;
    const columnVisibilityModel = {
        PackageDetailID: false,
        ItemId: false,
        SizeId: false,
        FlavorId: false,
    };
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setItemFileImage(file);
            setItemFileNameURL(URL.createObjectURL(file));
            setIsImageRemoved(false);
        }
    };
    const handleDeleteImage = () => {
        setItemFileImage(null);
        setItemFileNameURL('');
        setIsImageRemoved(true);
    };


    async function handleDelete(RelatedId, RelatedType) {
        try {
            const ConstDelImg = await DeleteFileUpload(RelatedId, RelatedType).then(result => {

                return ConstDelImg;
            });
        } catch (error) {
            console.error("Error during deletion:", error);
        }
    }


    const columns = [
        { field: 'PackageDetailID', headerName: 'PackageDetailID', width: 90 },
        { field: 'ItemId', headerName: 'ItemId', width: 90 },
        { field: 'SizeId', headerName: 'Size ID', width: 150 },
        { field: 'FlavorId', headerName: 'Flavor ID', width: 150 },
        { field: 'SizeName', headerName: 'Size', width: 150 },
        { field: 'FlavorName', headerName: 'Flavor', width: 150 },
        //{ field: 'OriginalPrice', headerName: 'Original Price', width: 150 },
        { field: 'CurrentPrice', headerName: 'Price', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 100 },
        { field: 'Enabled', headerName: 'Enabled', width: 120, type: 'boolean' },
        { field: 'IsOnSale', headerName: 'On Sale', width: 120, type: 'boolean' },
        { field: 'IsOutOfStock', headerName: 'Out of Stock', width: 150, type: 'boolean' },
        { field: 'IsNewArrival', headerName: 'New Arrival', width: 150, type: 'boolean' },
        { field: 'Discount', headerName: 'Discount', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'left', gap: '1px'
                }}>
                    <IconButton onClick={() => handleEditPackageDetail(params.row.PackageDetailID)}>
                        <EditIcon sx={{ color: blue[300] }} />
                    </IconButton>
                    <IconButton onClick={() => handleDeletePackageDetail(params.row.PackageDetailID)}>
                        <DeleteIcon sx={{ color: pink[500] }} />
                    </IconButton>
                </Box>
            ),
        },
    ];

    const handleOpenImageInNewTab = (image) => {
      
        window.open(image, '_blank', 'noopener,noreferrer');
    };
    const parseSubCatIds = (ids) => {
        return Array.isArray(ids)
            ? ids.map(id => Number(id))
            : ids ? ids.split(',').map(id => Number(id.trim())) : [];
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" sx={{
            '& .MuiPaper-root': {
                maxWidth: '800px', 
            },
        }}>
            <DialogTitle>{item ? 'Edit Item' : 'Add New Item'}</DialogTitle>
            <Formik
                initialValues={{
                    ItemId: item ? item.ItemId : null,
                    SubCatId: item ? parseSubCatIds(item.SubCatId) : [],    
                    BrandId: item ? item.BrandId : null,
                    ItemName: item ? item.ItemName : null,
                    ItemNutritionFacts: item ? item.ItemNutritionFacts : null,
                    ItemDescription: item ? item.ItemDescription : null,
                    ItemDirections: item ? item.ItemDirections : null,
                    ItemIngredients: item ? item.ItemIngredients : null,
                    ItemWarnings: item ? item.ItemWarnings : null,
                    ItemSupportGoals: item ? item.ItemSupportGoals : null,
                    ItemOrderNbr: item ? item.ItemOrderNbr : null,
                    ItemPrice: item ? item.ItemPrice : null,
                    ItemPriceBeforeSale: item ? item.ItemPriceBeforeSale : null,
                    IsItemVisible: item ? item.IsItemVisible : true,
                    IsItemIsNewArrival: item ? item.IsItemIsNewArrival : false,
                    IsItemIsOutOfStock: item ? item.IsItemIsOutOfStock : false,
                    IsItemIsOnSale: item ? item.IsItemIsOnSale : false,
                    IsItemOnOffre: item ? item.IsItemOnOffre : false,
                    IsItemEnabled: item ? item.IsItemEnabled : true, 
                    ListPackDet: PackageDetails // No need to check, as PackageDetails is always initialized
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        //update
                        if (item) {
                            values.ListPackDet = PackageDetails;
                            const updateRes = await UpdateItems(values);
                            const errorMsg = updateRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error updating Item: ' + errorMsg);
                                return;
                            } else {
                                if (isImageRemoved && originalImageUrl) {
                                    await handleDelete({ RelatedId: item?.ItemId, RelatedType: "ItemImage" })
                                } else if (ItemFileImage && ItemFileNameURL !== originalImageUrl) {
                                    await uploadImage(ItemFileImage, "ItemImage", item.ItemId, JSON.stringify(values));
                                }

                                //delete image updated
                                PackageDetails.forEach((PackDet, PackDetailIndex) => {
                                    PackDet?.RemovedImages?.forEach((RemvImg, ImgIndex) => {
                                        if (RemvImg?.PackageDetailsImageId) {
                                            const ConstDelImg = handleDelete({ RelatedId: RemvImg?.PackageDetailsImageId, RelatedType: "PackageDetailsImage" })

                                            const errorMsg = ConstDelImg?.Error?.Message;
                                            if (errorMsg) {
                                                setError('ItemUpdated but failed to delete image: ' + errorMsg);
                                                return;
                                            }
                                        }
                                    });

                                    //upload image updated
                                    PackDet?.Images?.forEach((Img, ImgIndex) => {
                                        //only call upload image in case of uploading image, so to distinghish it the [File] contain this attribute(last modified)
                                        if (Img?.lastModified)
                                            uploadImage(Img, "PackageDetailsImage", PackDet.PackageDetailID, JSON.stringify(PackDet))
                                    });
                                });
                            }


                        } else {
                            //Insert
                            values.ListPackDet = PackageDetails;
                            const insertRes = await InsertItems(values); 
                            const errorMsg = insertRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error adding Item: ' + errorMsg);
                                return;
                            }
                            else {
                                await uploadImage(ItemFileImage, "ItemImage", insertRes?.itemId, JSON.stringify(values));

                                PackageDetails.forEach((PackDet, IndexBackEnd) => {
                                    PackDet?.Images?.forEach((Img, IndexBackEnd) => {
                                        uploadImage(Img, "PackageDetailsImage", PackDet.PackageDetailID, JSON.stringify(PackDet))
                                    });
                                });
                            }
                        }
                        onSubmit();
                    } catch (error) {
                        console.error('Failed to submit Item form', error);
                        setError('Failed to submit Item form. Please try again.');
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                    <Form >
                        <DialogContent
                            sx={{ overflowY: 'hidden !important' }}>
                            {error && <Alert severity="error">{error}</Alert>}
                            <FormControl
                                fullWidth
                                margin="dense"
                                error={touched.SubCatId && Boolean(errors.SubCatId)}
                                required
                            > 
                                <Autocomplete
                                    multiple
                                    id="subCategory-autocomplete"
                                    options={subCats || []}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.SubCatNameFirstLang || ''}
                                    groupBy={(option) => option.CatNameFirstLang}
                                    value={item
                                        ? subCats.filter(cat => {
                                            // Convert values.SubCatId to an array of numbers
                                            const selectedSubCatIds = Array.isArray(values.SubCatId)
                                                ? values.SubCatId.map(id => Number(id))
                                                : values.SubCatId.split(',').map(id => Number(id.trim()));
                                            return selectedSubCatIds.includes(Number(cat.SubCatId));
                                        })
                                        : subCats.filter(cat => {
                                            // Convert values.SubCatId to an array of numbers
                                            const selectedSubCatIds = Array.isArray(values.SubCatId)
                                                ? values.SubCatId.map(id => Number(id))
                                                : values.SubCatId.split(',').map(id => Number(id.trim()));
                                            return selectedSubCatIds.includes(Number(cat.SubCatId));
                                        })
                                    }
                                    onChange={(event, newValue) => {
                                        // Convert selected options to an array of numbers 
                                        const selectedSubCatIds = newValue.map(option => Number(option.SubCatId));
                                        setFieldValue('SubCatId', selectedSubCatIds);
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <MenuItem {...props} key={option.SubCatId} value={option.SubCatId}>
                                            <Checkbox checked={selected} />
                                            <ListItemText primary={option.SubCatNameFirstLang} />
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Sub-Category" variant="outlined" />
                                    )}
                                />

                                {errors.SubCatId && <div style={{ color: 'red' }}>{errors.SubCatId}</div>}
                            </FormControl>
                            <FormControl fullWidth margin="dense" error={touched.BrandId && Boolean(errors.BrandId)}>
                                <InputLabel id="Brand-select-label">Brand</InputLabel>
                                <Select
                                    label="Brand"
                                    labelId="Brand-select-label"
                                    id="Brand-select"
                                    name="BrandId"
                                    value={values.BrandId}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={0} value={0}>
                                        <em>None</em>
                                    </MenuItem>
                                    {brands?.map((brand) => (
                                        <MenuItem key={brand.BrandId} value={brand.BrandId}>
                                            {brand.BrandName}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {errors.BrandId && <div style={{ color: 'red' }}>{errors.BrandId}</div>}
                            </FormControl>
                            <TextField
                                required
                                id="txtName"
                                label="Item Name"
                                name="ItemName"
                                value={values.ItemName}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                error={touched.ItemName && Boolean(errors.ItemName)}
                                helperText={touched.ItemName && errors.ItemName}
                            />
                            {/*<TextField*/}
                            {/*    id="txtNameDesc"*/}
                            {/*    label="Item Description"*/}
                            {/*    name="ItemDescription"*/}
                            {/*    value={values.ItemDescription}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    multiline*/}
                            {/*    rows={8}*/}
                            {/*    fullWidth*/}
                            {/*    margin="dense"*/}
                            {/*/>*/}
                            <br/>
                            <br />
                            <RichTextbox
                                title="Nutrition Facts"
                                name="ItemNutritionFacts"
                                value={values.ItemNutritionFacts}
                                onChange={(value) => setFieldValue('ItemNutritionFacts', value)}

                            /> 
                            <br />
                            <RichTextbox 
                                title="Description"
                                name="ItemDescription"
                                value={values.ItemDescription}
                                onChange={(value) => setFieldValue('ItemDescription', value)}

                            />
                            <br /> 
                            <RichTextbox
                                title="Ingredients"
                                name="ItemIngredients"
                                value={values.ItemIngredients}
                                onChange={(value) => setFieldValue('ItemIngredients', value)}

                            />
                            <br /> 
                            <RichTextbox 
                                title="Directions"
                                name="ItemDirections"
                                value={values.ItemDirections}
                                onChange={(value) => setFieldValue('ItemDirections', value)}

                            /> 
                            <br />
                            <RichTextbox
                                title="Warnings"
                                name="ItemWarnings"
                                value={values.ItemWarnings}
                                onChange={(value) => setFieldValue('ItemWarnings', value)}

                            /> 
                            <br />
                            <RichTextbox
                                title="Support Your Goals"
                                name="ItemSupportGoals"
                                value={values.ItemSupportGoals}
                                onChange={(value) => setFieldValue('ItemSupportGoals', value)}

                            />
                            <br />


                            <TextField
                                id="Price" 
                                label=" Price"
                                name="ItemPrice"
                                value={values.ItemPrice}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                            />
                            {values.IsItemIsOnSale && (
                                 <TextField
                                id="ItemPriceBeforeSale" 
                                label="Price Before Sale"
                                name="ItemPriceBeforeSale"
                                value={values.ItemPriceBeforeSale}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                            /> 
                            )}

                            <TextField
                                id="txtOrder"
                                type="number"
                                label="Order Number"
                                name="ItemOrderNbr"
                                value={values.ItemOrderNbr}
                                onChange={handleNumberChange(handleChange)}
                                fullWidth
                                margin="dense"
                            />
                            
                            {/*<FormControlLabel*/}
                            {/*    sx={{ ml: '5px' }}*/}
                            {/*    labelPlacement="start"*/}
                            {/*    label="Visible"*/}
                            {/*    control={*/}
                            {/*        <Checkbox*/}
                            {/*            checked={values.IsItemVisible}*/}
                            {/*            onChange={handleChange}*/}
                            {/*            name="IsItemVisible"*/}
                            {/*            color="primary"*/}
                            {/*        />*/}
                            {/*    }*/}
                            {/*/>*/}
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={values.IsItemVisible}
                                        onChange={handleChange}
                                        name="IsItemVisible"
                                        color="primary"
                                    />
                                }
                                label="Visible"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.IsItemIsOnSale}
                                        onChange={handleChange}
                                        name="IsItemIsOnSale"
                                        color="primary"
                                    />
                                }
                                label="On Sale"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.IsItemOnOffre}
                                        onChange={handleChange}
                                        name="IsItemOnOffre"
                                        color="primary"
                                    />
                                }
                                label="On Offre"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.IsItemEnabled}
                                        onChange={handleChange}
                                        name="IsItemEnabled"
                                        color="primary"
                                    />
                                }
                                label="Enabled"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.IsItemIsOutOfStock}
                                        onChange={handleChange}
                                        name="IsItemIsOutOfStock"
                                        color="primary"
                                    />
                                }
                                label="Out of Stock"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.IsItemIsNewArrival}
                                        onChange={handleChange}
                                        name="IsItemIsNewArrival"
                                        color="primary"
                                    />
                                }
                                label="New Arrival"
                            />


                            <input
                                accept="image/*"
                                id="ImgItemFile-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            <label htmlFor="ImgItemFile-upload">
                                <Button variant="outlined" component="span" fullWidth>
                                    Upload Default Image
                                </Button>
                            </label>

                           
                            {ItemFileNameURL && (

                                <Grid item xs={6} md={4}>
                                    <Typography variant="body1" gutterBottom>
                                        Item Image
                                    </Typography>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <img id="myImggg" src={ItemFileNameURL} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: '85px', // Adjust as necessary to position buttons next to the image
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start', // Align buttons to start of the container
                                    }}>
                                        <IconButton
                                            style={{
                                                color: 'white',
                                                backgroundColor: '#eee',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#ddd',
                                                },
                                            }}
                                        >
                                            <DeleteIcon onClick={() => handleDeleteImage()} sx={{ color: pink[500] }} />
                                        </IconButton>
                                        <IconButton
                                            style={{
                                                color: 'white',
                                                backgroundColor: '#eee',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                marginTop: '5px', // Add some spacing between buttons if needed
                                                '&:hover': {
                                                    backgroundColor: '#ddd',
                                                },
                                            }}
                                        >
                                            <ZoomInOutlinedIcon onClick={() => handleOpenImageInNewTab(ItemFileNameURL)} sx={{ color: green[500] }} />
                                        </IconButton>
                                    </div>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </Grid>
                            
                            )}
                            {/*{ItemFileNameURL && (*/}
                            {/*    <div>*/}
                            {/*        <img id="myImggg" src={ItemFileNameURL} alt="Uploaded" style={{ width: '100px', height: '100px' }} />*/}
                            {/*        <Button variant="contained" color="secondary" onClick={handleDeleteImage}>*/}
                            {/*            Delete Image*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            <Typography variant="h6" gutterBottom>
                                Package Details
                            </Typography>
                            <div style={{ height: 400, width: '100%' }}>


                                <Button
                                    sx={{
                                        bgcolor: green[700],
                                        color: 'white',
                                        border: '1px solid',
                                        borderColor: 'Green',
                                        '&:hover': {
                                            bgcolor: green[700],
                                            borderColor: green[700],
                                        },
                                    }}
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        setIsPackageDetailDialogOpen(true);
                                        setEditingPackageDetail(null);
                                    }}
                                >
                                    Add Package Detail
                                </Button>

                                <DataGrid
                                    columnVisibilityModel={columnVisibilityModel}
                                    getRowId={getRowId}
                                    rows={PackageDetails}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                {item ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
            {isPackageDetailDialogOpen && (
                <PackageDetailDialog
                    open={isPackageDetailDialogOpen}
                    onClose={() => setIsPackageDetailDialogOpen(false)}
                    onSave={handleAddPackageDetail}
                    packageDetail={editingPackageDetail}
                />
            )}
        </Dialog>
    );
};

export default ItemFormDialog;
