import React from 'react';
import Dumble from '../collection/Dumble_NB.gif'; 

const Progress = ({ isLoading }) => {
    return (
        <div> 
            <br/>
            <div className={`progress-container ${isLoading ? 'visible' : 'hidden'}`}>
                <img src={Dumble} alt="Loading..." />
        </div>
  
        </div>
    );
};

export default Progress;
